/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { MenuItem, TextField } from "@mui/material";

function InputSelect({
  name,
  label,
  value,
  values,
  onChange,
  style = { width: "100%" },
  type = "option",
  disabled = false,
}) {
  return (
    <TextField
      select
      disabled={disabled}
      name={name}
      label={label}
      value={value}
      onChange={onChange}
      style={style}
      SelectProps={{
        native: type == "option" ? true : false,
      }}
      InputLabelProps={{ shrink: true }}
      margin="dense"
      variant="outlined"
    >
      {type == "option"
        ? values.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))
        : values.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
    </TextField>
  );
}

export default InputSelect;
