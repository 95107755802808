import { Modal as MaterialModal, Backdrop, Fade } from '@mui/material';
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  paper: {
      padding: 20,
      boxShadow: 10,
      borderRadius: 10,
      position: 'absolute',
      backgroundColor: "#FFFFFF",
    },
});

const Modal = (props) => {
    const classes = useStyles();
    return (
        <MaterialModal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={props.open}
            BackdropComponent={Backdrop}
        >
            <Fade in={props.open}>
                <div className={classes.paper} style={{...props.style, width: props.width || 880, height: props.height || 550}}>
                    { props.children }
                </div>
            </Fade>
        </MaterialModal>
    );
}

export default Modal;