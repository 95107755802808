import { useRef } from "react";

export default function useDebounce(fn, delay = 500){
    const timoutRef = useRef(null);

    function debaunceFn(...args){
        window.clearTimeout(timoutRef.current);
        timoutRef.current = window.setTimeout( () => {
            fn(...args)
        }, delay);
    }

    return debaunceFn;
}

//exemplo de uso:
//const debounceChange = useDebounce(onChange, 500);