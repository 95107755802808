import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Box, Card, CardContent, CardHeader, Divider } from '@mui/material';

ChartJS.register(ArcElement, Tooltip, Legend);

const DoughnutChart = ({title="", labels, values, colorsBc, colorsBg, style}) => {
  const data = {
    labels,
    datasets: [
      {
        data: values,
        backgroundColor: colorsBg,
        borderColor: colorsBc,
        borderWidth: 1,
      },
    ],
  };

  const options = {
    animation: {
      animateScale: true,
      animateRotate: true,
      duration: 300
    },
    responsive: true,
    circumference: 180,
    rotation: -90,
    plugins: {
      datalabels: {
        backgroundColor: function(context) {
          return context.dataset.borderColor;
        },
        borderColor: function(context) {
          return context.dataset.borderColor;
        },
        borderRadius: 25,
        borderWidth: 2,
        color: 'white',
        display: function(context) {
          var dataset = context.dataset;
          var count = dataset.data.length;
          var value = dataset.data[context.dataIndex];
          return value > count * 1.5;
        },
        font: {
          weight: 'bold',
          size: 20,
        },
        padding: 6,
        anchor: 'start'
      }
    }
  }

  return (
    <Card style={{width: '100%', height: '100%'}}>
      <CardHeader title={title} />
      <Divider />
      <CardContent>
        <Box style={style}>
          <Doughnut 
            data={data} 
            plugins={[ChartDataLabels, 
              //ChartDoughnut, 
              {
              beforeDraw: function(chart) {
                var width = chart.width,
                    height = chart.height,
                    ctx = chart.ctx;

                ctx.restore();
                var fontSize = (height / 160).toFixed(2);
                ctx.font = fontSize + "em roboto";
                ctx.textBaseline = "top";
                var text = data.datasets[0].data.reduce((a, b) => a + b, 0),
                textX = Math.round((width - ctx.measureText(text).width) / 2),
                textY = height / 1.6;
                ctx.fillStyle = 'rgba(3, 36, 47, 1)';
                ctx.fillText(text, textX, textY);
                ctx.save();
              } 
            }
          ]} 
            options={options} 
          />
        </Box>
      </CardContent>
    </Card>
  );
};

export default DoughnutChart;
//chartjs-plugin-datalabels