import { baseUrl } from "./http";

const url = `${baseUrl()}`;
const STORAGE_KEY = "accessToken";

const UserApi = {
  /**
   * Faz um GET com id do modulo para obter as permissões do usuário
   * @param {Number} module_id
   * @returns
   */
  getPermission: async (module_id) => {
    return new Promise((resolve, reject) => {
      fetch(`${url}/v2/core/users/permissions/modules/${module_id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem(STORAGE_KEY)}`,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  /**
   * faz um GET pelo subbrupo dos parametros e carrega todos os parametros que o
   * usuário está habilitado ou cadastrado.
   * @param {String} subgroup
   * @returns
   */
  getParameters: async (params) => {
    let urlParams = new URLSearchParams();
    for (const key in params) {
      urlParams.append("params", params[key]);
    }
    return new Promise((resolve, reject) => {
      fetch(`${url}/v2/core/users/permissions/parameters?${urlParams.toString()}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem(STORAGE_KEY)}`,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

export default UserApi;