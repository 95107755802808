import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        padding: theme.spacing(0.5),
        paddingLeft: 10,
        paddingRight: 10,
        marginBottom: 5,
    },
    rootFilter: {
      display: 'flex',
      justifyContent: 'left',
      flexWrap: 'wrap',
      listStyle: 'none',
      padding: "3px",
      margin: 0,
    },
    chip: {
      margin: "3px",
    },
    inputFilter: {
        display: 'flex',
        flex: 1 
    }
  }));

  export default useStyles;