import React, { useState } from 'react'
import copy from 'copy-to-clipboard';
import { RiFileCopy2Line } from 'react-icons/ri';
import { ClickAwayListener, Tooltip } from '@mui/material';

function CopyClipboard({ value, color = "#31b7bc", style={} }) {
  const [open, setOpen] = useState(false);

  const copied = (text) => {
    copy(text);
    setOpen(true);
    window.setTimeout(() => setOpen(false), 2000, []);
  };

  return (
    <div style={{...style, display: "flex", flexDirection: "row" }}>
      {value}
      {value && (
        <ClickAwayListener onClickAway={() => copied(value)}>
          <Tooltip
            PopperProps={{
              disablePortal: true,
            }}
            onClose={() => copied(value)}
            open={open}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title="Copiado."
          >
            <button
              onClick={() => copied(value)}
              style={{
                background: "transparent",
                border: "none",
                width: 10,
                height: 10,
                cursor: "pointer",
                marginLeft: 2,
              }}
            >
              <RiFileCopy2Line color={color} />
            </button>
          </Tooltip>
        </ClickAwayListener>
      )}
    </div>
  );
}

export default CopyClipboard