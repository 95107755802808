import { combineReducers, createStore, applyMiddleware } from "redux";
import multi from "redux-multi";
import thunk from "redux-thunk";

import user from "src/store/ducks/user";
import user_groups from "src/store/ducks/user_groups";
import user_parameters from 'src/store/ducks/user_parameters';
import product from 'src/store/ducks/products';
import products_variations from 'src/store/ducks/products_variations';
import picking_box from 'src/store/ducks/picking_box';
import picking from 'src/store/ducks/picking';
import packing from 'src/store/ducks/packing';
import inventory from 'src/store/ducks/inventory';
import picking_priority from 'src/store/ducks/picking_priority';
import stock from 'src/store/ducks/stock';
import dashboard from 'src/store/ducks/dashboard';
import report from 'src/store/ducks/report';
import stock_transfer from 'src/store/ducks/stock_transfer';

const devTools =
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__();

const reducers = combineReducers({
  user,
  user_groups,
  user_parameters,
  product,
  products_variations,
  picking_box,
  picking,
  packing,
  inventory,
  picking_priority,
  stock,
  dashboard,
  report,
  stock_transfer
});

export default applyMiddleware(thunk, multi)(createStore)(
  reducers,
  process.env.NODE_ENV === "development" ? devTools : undefined
);
