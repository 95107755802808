import { httpfbnd } from "src/services";
import queryString from "query-string";

// Action Types
export const Types = {
  STOCK_LOADING: "stock/loading",
  STOCK_SET_STORAGE: "stock/set/storage",
  STOCK_SET_TYPES: "stock/set/types",
  STOCK_SET_ADDRESS: "stock/set/address"
};

// Reducer
const initialState = {
  loading: false,
  storages: [],
  types: [],
  stock_address: []
};

// Action Creators
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.STOCK_LOADING:
      return { ...state, loading: action.payload };
    case Types.STOCK_SET_STORAGE:
      return { ...state, storages: action.payload };
    case Types.STOCK_SET_TYPES:
      return { ...state, types: action.payload };
    case Types.STOCK_SET_ADDRESS:
      return { ...state, stock_address: action.payload };
    default:
      return state;
  }
}

export const Creators = {
  stockLoading: (value) => ({
    type: Types.STOCK_LOADING,
    payload: value,
  }),
  stockSetStorage: (value) => ({
    type: Types.STOCK_SET_STORAGE,
    payload: value,
  }),
  stockSetTypes: (value) => ({
    type: Types.STOCK_SET_TYPES,
    payload: value,
  }),
  stockSetAddress: (value) => ({
    type: Types.STOCK_SET_ADDRESS,
    payload: value,
  }),
  getStorageAddressList: (filters) => {
    const qry = queryString.stringify(filters, { arrayFormat: "index" });
    return new Promise((resolve, reject) => {
      httpfbnd
        .get(`/stock/address?${qry}`, { timeout: 1000 * 15 })
        .then(({ data }) => resolve(data))
        .catch((error) => reject(error));
    });
  },
  getStorageList: () => {
    return new Promise((resolve, reject) => {
      httpfbnd
        .get(`/stock/storage`, { timeout: 1000 * 15 })
        .then(({ data }) => resolve(data))
        .catch((error) => reject(error));
    });
  },
  getStorageTypes: () => {
    return new Promise((resolve, reject) => {
      httpfbnd
        .get(`/inventory/types`, { timeout: 1000 * 15 })
        .then(({ data }) => resolve(data))
        .catch((error) => reject(error));
    });
  },
  deleteStorageMapped: ({address_id, variation_id}) => {
    return new Promise((resolve, reject) => {
      httpfbnd
        .delete(`/stock/mapped/${address_id}/${variation_id}`, {
          timeout: 1000 * 15,
        })
        .then(({ data }) => resolve(data))
        .catch((error) => reject(error));
    });
  }
};
