import { http } from "src/services";

// Action Types
export const Types = {
  USER_PARAMETERS_LOADING: "user/parameters/loading",
  USER_PARAMETER_SET_DATA: "user/parameters/set/data",
  USER_PARAMETERS_SET_DATA: "user/parameters/list/set/data",
  USER_PARAMETERS_SET_PAGING: "user/parameters/list/set/paging",
  USER_PARAMETERS_SET_PARAMETERS: "user/set/user/parameters",
  USER_PARAMETERS_SET_PARAMETERS_PAGING: "user/parameters/list/parameters/set/paging",
};

// Reducer
const initialState = {
  loading: false,
  parameters: [],
  user_parameters: [],
  user_parameters_paginate: {
    page: 0,
    size: 15,
    count: 0,
  },
  parameter: {},
  paginate: {
    page: 0,
    size: 15,
    count: 0,
  },
};

// Action Creators
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.USER_PARAMETERS_LOADING:
      return { ...state, loading: action.payload };
    case Types.USER_PARAMETERS_SET_PAGING:
      return { ...state, paginate: { ...action.payload } };
    case Types.USER_PARAMETERS_SET_DATA:
      return { ...state, parameters: action.payload };
    case Types.USER_PARAMETER_SET_DATA:
      return { ...state, parameter: action.payload };
    case Types.USER_PARAMETERS_SET_PARAMETERS:
      return { ...state, user_parameters: action.payload };
    case Types.USER_PARAMETERS_SET_PARAMETERS_PAGING:
      return { ...state, user_parameters_paginate: { ...action.payload } };
    default:
      return state;
  }
}

export const Creators = {
  parametersLoading: (value) => ({
    type: Types.USER_PARAMETERS_LOADING,
    payload: value,
  }),
  parametersSetData: (users) => ({
    type: Types.USER_PARAMETERS_SET_DATA,
    payload: users,
  }),
  parametersSetPaginate: (paging) => ({
    type: Types.USER_PARAMETERS_SET_PAGING,
    payload: paging,
  }),
  parameterSetData: (parameter) => ({
    type: Types.USER_PARAMETER_SET_DATA,
    payload: parameter,
  }),
  userSetParameters: (user_parameters) => ({
    type: Types.USER_PARAMETERS_SET_PARAMETERS,
    payload: user_parameters,
  }),
  userSetParametersPaginate: (paging) => ({
    type: Types.USER_PARAMETERS_SET_PARAMETERS_PAGING,
    payload: paging,
  }),
  findAllParameters: (params) => {
    return (dispatch) => {
      http
        .get(
          `/core/parameters/users/groups?${new URLSearchParams({ ...params })}`
        )
        .then(({ data: { rows, page, size, count } }) => {
          dispatch([
            Creators.parametersSetData(rows),
            Creators.parametersSetPaginate({ page, size, count }),
            Creators.parametersLoading(false),
          ]);
        })
        .catch((e) => {
          dispatch([Creators.userLoading(false)]);
        });
    };
  },
  findParameter: (id, callback) => {
    return (dispatch) => {
      http
        .get(`/core/parameters/users/groups/${parseInt(id)}`)
        .then(({ data }) => {
          dispatch([
            Creators.parameterSetData(data),
            Creators.userLoading(false),
          ]);
          callback(data);
        })
        .catch((e) => {
          dispatch([Creators.userLoading(false)]);
        });
    };
  },
  findUserParameters: (params = {}) => {
    return (dispatch) => {
      http
        .get(
          `/core/parameters/users/values?${new URLSearchParams({
            page: 0,
            size: 15,
            ...params,
          })}`
        )
        .then(({ data: { rows, page, size, count } }) => {
          dispatch([
            Creators.userSetParameters(rows),
            Creators.userSetParametersPaginate({ page, size, count }),
            Creators.parametersLoading(false),
          ]);
        })
        .catch((e) => {
          dispatch([Creators.parametersLoading(false)]);
        });
    };
  },
  addUserParameter: (userId, parameterId, value, paginate = {}, callback) => {
    return (dispatch) => {
      http
        .post(`/core/parameters/users/values`, { userId, parameterId, value })
        .then(() => {
          dispatch([Creators.findUserParameters({ userId, ...paginate })]);
          callback({ sucesso: true });
        })
        .catch((e) => {
          dispatch(Creators.parametersLoading(false));
          callback({ sucesso: false });
        });
    };
  },
  delUserParameter: (valueId, userId, paginate = {}, callback) => {
    return (dispatch) => {
      http
        .delete(`/core/parameters/users/values/${valueId}`)
        .then(() => {
          dispatch([Creators.findUserParameters({ userId, ...paginate })]);
          callback({ sucesso: true });
        })
        .catch((e) => {
          dispatch(Creators.parametersLoading(false));
          callback({ sucesso: false });
        });
    };
  },
};
