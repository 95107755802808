import { Button, Hidden, Tooltip, Typography } from '@mui/material';
import { makeStyles } from "@mui/styles";
import useDetectKeyboardOpen from "use-detect-keyboard-open";

const useStyles = makeStyles({
    fullPage: {
        width: '100%',
        padding: '10px 10px 5px 10px',
    },
    titlePage:{
        padding: '0 0 5px 10px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    }
})

function Container({ title, children, buttonsRight, style={} }) {
    const classes = useStyles();
    const isKeyboardOpen = useDetectKeyboardOpen();

    return (
      <div className={classes.fullPage} style={style}>
        <div className={classes.titlePage}>
          {typeof title === String ? (
            <Typography sx={{ marginTop: 5 }}>{title}</Typography>
          ) : (
            title
          )}
          <Hidden smDown>
            <div>
              {buttonsRight &&
                buttonsRight.map((buttons, index) => (
                  <Tooltip key={index} title={buttons.tooltip}>
                  <Button
                    ref={buttons.ref}
                    {...buttons?.otherParams}
                    className={classes.buttons}
                    sx={{
                      opacity: buttons.disabled ? 0.5 : 1,
                      ...buttons?.style,
                    }}
                    disabled={buttons.disabled}
                    onClick={(e) => buttons.onClick(e)}
                    key={index}
                  >
                    {buttons.icon}&nbsp;{buttons.title}
                    </Button>
                    </Tooltip>
                ))}
            </div>
          </Hidden>
        </div>
        {children}
        {!isKeyboardOpen && (
          <Hidden smUp>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                position: "absolute",
                right: 0,
                bottom: 0,
                left: 0,
                zIndex: 10,
                backgroundColor: "#F8FAFC",
              }}
            >
              {buttonsRight &&
                buttonsRight.map((buttons, index) => (
                  <Button
                    className={classes.buttons}
                    {...buttons.otherParams}
                    sx={{
                      display: "flex",
                      flex: 1,
                      height: 70,
                      opacity: buttons.disabled ? 0.5 : 1,
                      ...buttons.style,
                    }}
                    disabled={buttons.disabled}
                    onClick={(e) => buttons.onClick(e)}
                    key={index}
                  >
                    {buttons.icon}&nbsp;{buttons.title}
                  </Button>
                ))}
            </div>
          </Hidden>
        )}
      </div>
    );
}

export default Container
