/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";

import useDebounce from "src/helpers/debounce";

const useStyles = makeStyles({
  textField: {
    width: "100%",
    maxWidth: "100%",
  },
});

function InputDebounce({
  value,
  onChange,
  disabled = false,
  onKeyPress,
  InputProps,
  placeholder,
  style,
  label = "",
  debounceTime = 500,
}) {
  const classes = useStyles();
  const [displayValue, setDisplayValue] = useState(value);
  const debounceChange = useDebounce(onChange, debounceTime);

  function handleChange(event) {
    setDisplayValue(event.target.value);
    debounceChange(event.target.value);
  }

  useEffect(() => {
    if (value != displayValue) {
      setDisplayValue("");
    }
  }, [value]);

  return (
    <TextField
      className={classes.textField}
      label={label}
      style={style}
      disabled={disabled}
      value={displayValue}
      onChange={handleChange}
      onKeyPress={onKeyPress}
      fullWidth
      InputProps={InputProps}
      placeholder={placeholder}
      InputLabelProps={{ shrink: true }}
      variant="outlined"
      margin="dense"
    />
  );
}

export default InputDebounce;
