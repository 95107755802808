import axios from "axios";

const baseUrl = () => {
  if (process.env.NODE_ENV !== "development") {
    return process.env.REACT_APP_WCA_FB_URL_PRODUCTION;
  } else {
    return process.env.REACT_APP_WCA_FB_URL_DEVELOPMENT;
  }
};

const http = axios.create({
  baseURL: baseUrl(),
  timeout: 30000,
});

export default http;
