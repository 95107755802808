import { Card, Tooltip, Typography } from "@mui/material";
import moment from "moment";
import { Clock } from "react-feather";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  getMobileOperatingSystem,
  getScreenSize,
  zerosEsquerda,
} from "src/utils/getUtils";
import PlatformSalesIcon from "../PlatformSalesIcon";
import useStyles from "./styles";

function SalesOrderCard({
  hideId = false,
  orders = [],
  marginBottom = 50,
  isMinus = false,
  timeIntegrate = false,
}) {
  const classes = useStyles();
  function CardSeparator({ hide }) {
    return (
      <span
        className={classes.separator}
        style={{ backgroundColor: !hide ? "rgba(61,61,61, 0.1)" : null }}
      />
    );
  }

  function diffHours(data1, data2) {
    let dif =
      Date.UTC(data1.getYear(), data1.getMonth(), data1.getDate(), 0, 0, 0) -
      Date.UTC(data2.getYear(), data2.getMonth(), data2.getDate(), 0, 0, 0);

    dif = Math.abs(dif / 1000 / 60 / 60);
    let difH = Math.abs(data2.getHours() - data1.getHours());
    let difM = Math.abs(data2.getMinutes() - data1.getMinutes());
    let difS = Math.abs(data2.getSeconds() - data1.getSeconds());
    return getMobileOperatingSystem() === "unknown"
      ? zerosEsquerda(dif + difH, 2) +
          ":" +
          zerosEsquerda(difM, 2) +
          ":" +
          zerosEsquerda(difS, 2)
      : zerosEsquerda(dif + difH, 2) + ":" + zerosEsquerda(difM, 2);
  }

  function ItemCard({ item, key }) {
    return (
      <div key={key}>
        <Card className={classes.cardRowStyle} style={{ marginTop: 5 }}>
          {!hideId && (
            <>
              <Typography
                className={classes.itemLista}
                style={{ minWidth: 80 }}
                align="right"
              >
                {item.order_id}
              </Typography>
              <CardSeparator />
            </>
          )}
          <div>
            <Typography
              className={classes.itemLista}
              style={{
                minWidth: getMobileOperatingSystem() === "unknown" ? 90 : 50,
              }}
              align="center"
            >
              {getMobileOperatingSystem() === "unknown"
                ? moment(item?.created_at).format("DD/MM/YYYY")
                : moment(item?.created_at).format("DD/MM")}
            </Typography>
            <Tooltip title="Tempo aguardando separação">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  backgroundColor: "#e9e9e9",
                  alignItems: "center",
                  borderRadius: 5,
                }}
              >
                <Clock size={13} style={{ marginLeft: "auto" }} />
                <Typography
                  variant="body1"
                  style={{ fontSize: 12, marginRight: "auto", marginLeft: 2 }}
                >
                  {diffHours(
                    moment(item.integrated_at).toDate(),
                    moment().toDate()
                  )}
                </Typography>
              </div>
            </Tooltip>
          </div>
          <CardSeparator />
          <PlatformSalesIcon
            platform={item?.marketplace?.name.split("_")[0].toLowerCase()}
            width={35}
            height={35}
            style={{
              marginTop: "auto",
              marginBottom: "auto",
              marginLeft: 5,
              marginRight: 5,
            }}
          />
          {getMobileOperatingSystem() === "unknown" && (
            <div>
              <Typography
                className={classes.itemLista}
                style={{ minWidth: 150, marginLeft: 5, fontSize: 12 }}
              >
                {item?.marketplace?.name}
              </Typography>
              <Typography
                variant="caption"
                className={classes.itemLista}
                style={{ minWidth: 150, marginLeft: 5 }}
              >
                {item?.shipping?.method_name}
              </Typography>
            </div>
          )}
          <CardSeparator />
          {getMobileOperatingSystem() === "unknown" && (
            <>
              <Typography
                align="left"
                className={classes.itemLista}
                style={{ width: "100%" }}
              >
                {item?.order_id_marketplace}
              </Typography>
              <CardSeparator />
            </>
          )}
          {hideId ? (
            <Typography
              variant="h4"
              style={{ width: "100%", marginTop: "auto", marginBottom: "auto" }}
              align="center"
            >
              {item?.order_id}
            </Typography>
          ) : (
            <Typography
              style={{ width: "100%", marginTop: "auto", marginBottom: "auto" }}
              align="left"
            >
              {item?.picking_info?.current_user}
            </Typography>
          )}
        </Card>
      </div>
    );
  }

  return (
    <div
      style={{
        height:
          (orders.length > 0
            ? getScreenSize().height -
              (getMobileOperatingSystem() === "unknown" ? 170 : 230)
            : 60) - (isMinus ? marginBottom : 0),
        marginBottom: marginBottom,
      }}
    >
      <div
        className={classes.cardRowStyle}
        style={{
          height: 40,
          paddingBottom: 0,
          backgroundColor: "#31b7bc",
          color: "#FFF",
          borderBottom: "3px solid rgb(0, 36, 47)",
        }}
      >
        {!hideId && (
          <>
            <Typography style={{ minWidth: 80 }} align="left">
              ID
            </Typography>
            <CardSeparator hide />
          </>
        )}
        <Typography
          style={{
            minWidth: getMobileOperatingSystem() === "unknown" ? 80 : 50,
          }}
          align="left"
        >
          Data
        </Typography>
        <CardSeparator hide />
        <Typography
          align="left"
          style={{
            minWidth: getMobileOperatingSystem() === "unknown" ? 200 : 45,
          }}
        >
          Canal
        </Typography>
        <CardSeparator hide />
        {getMobileOperatingSystem() === "unknown" && (
          <>
            <Typography style={{ width: "100%" }} align="left">
              Pedido na plataforma
            </Typography>
            <CardSeparator hide />
          </>
        )}

        {hideId ? (
          <Typography style={{ width: "100%" }} align="center">
            Pedido
          </Typography>
        ) : (
          <Typography style={{ width: "100%" }} align="left">
            Separador atual
          </Typography>
        )}
      </div>
      <PerfectScrollbar>
        {orders.length > 0 ? (
          orders?.map((item, index) => <ItemCard item={item} key={index} />)
        ) : (
          <ItemCard item={orders} key={1} />
        )}
      </PerfectScrollbar>
    </div>
  );
}

export default SalesOrderCard;
