import { Card, Typography } from "@mui/material";
import _ from "lodash";
import React from "react";

import useStyles from "./styles";

function ProductCard({
  main_thumb_secure_url,
  owner_product_name,
  owner_product_model,
  owner_product_reference,
  owner_product_sku,
  gtin,
  attributes = [],
  stock = {},
  inventory,
  color = "#ffffff",
  message = "",
  transfer,
  separado,
}) {
  const classes = useStyles();

  return (
    <Card className={classes.cardRowStyle} style={{ backgroundColor: color }}>
      <img
        src={main_thumb_secure_url}
        className={classes.imgStyle}
        alt={`Imagem produto ${owner_product_sku}`}
      />
      <div style={{ display: "flex", padding: 5, flexDirection: "column" }}>
        <Typography align="left" variant="h5">
          {owner_product_name}
        </Typography>
        <div style={{ display: "flex", flexDirection: "row" }}>
          {owner_product_sku && (
            <Typography align="left" variant="body2">
              SKU: {owner_product_sku}
            </Typography>
          )}
          {owner_product_reference && (
            <Typography
              align="left"
              variant="body2"
              style={{ marginLeft: "10px" }}
            >
              Ref: {owner_product_reference}
            </Typography>
          )}
          {owner_product_model && (
            <Typography
              align="left"
              variant="body2"
              style={{ marginLeft: "10px" }}
            >
              Modelo: {owner_product_model}
            </Typography>
          )}
        </div>
        {attributes?.length > 0 && (
          <div style={{ display: "flex", flexDirection: "row", marginTop: 0 }}>
            {attributes?.map((item, index) => (
              <Typography
                align="left"
                variant="body1"
                style={{ marginLeft: index === 0 ? 0 : "20px" }}
              >
                {` ${_.startCase(item.name || item.attr_nome)}: ${
                  item.value || item.attr_valor
                }`}
              </Typography>
            ))}
          </div>
        )}
        {gtin && (
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Typography align="left" variant="body2">
              GTIN: {gtin}
            </Typography>
          </div>
        )}
        {inventory ? (
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Typography
              align="left"
              variant="h6"
              style={{
                marginRight: "10px",
                marginTop: "auto",
                marginBottom: "auto",
              }}
            >
              Inventário:
            </Typography>
            <Typography align="left" variant="h3">
              {inventory}
            </Typography>
          </div>
        ) : transfer >= 0 ? (
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Typography
              align="left"
              variant="h6"
              sx={{
                marginRight: 1,
                marginTop: "auto",
                marginBottom: "auto",
              }}
            >
              Solicitado:
            </Typography>
            <Typography align="left" variant="h3">
              {transfer}
            </Typography>
            <Typography
              align="left"
              variant="h6"
              sx={{
                marginLeft: 3,
                marginRight: 5,
                marginTop: "auto",
                marginBottom: "auto",
              }}
            >
              Separado:
            </Typography>
            <Typography align="left" variant="h3">
              {separado}
            </Typography>
          </div>
        ) : (
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Typography
              align="left"
              variant="body2"
              style={{ marginRight: "10px" }}
            >
              Estoque erp: {stock?.stock_erp?.physical_quantity}
            </Typography>
            <Typography
              align="left"
              variant="body2"
              style={{ marginRight: "10px" }}
            >
              Estoque wms: {stock?.physical_quantity}
            </Typography>
            <Typography
              align="left"
              variant="body2"
              style={{ marginRight: "10px" }}
            >
              Separado wms: {stock?.pickedup_reserved_quantity}
            </Typography>
          </div>
        )}
        {message.length > 0 && (
          <div style={{ display: "flex" }}>
            <Typography align="left" variant="subtitle2">
              {message}
            </Typography>
          </div>
        )}
      </div>
    </Card>
  );
}

export default ProductCard;
