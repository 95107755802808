/* eslint-disable eqeqeq */
import React from 'react'

import B2W from 'src/assets/images/b2w.png';
import LNX from 'src/assets/images/lnx.png';
import MZL from 'src/assets/images/mzl.png';
import SHO from 'src/assets/images/sho.png';
import MLB from 'src/assets/images/mlb.png';
import WZU from "src/assets/images/notimage.png";
import VIA from 'src/assets/images/via.png';
import RNN from "src/assets/images/ren.png";
import AMZ from "src/assets/images/amz.png";
import NSH from "src/assets/images/nsh.png";

function PlatformSalesIcon({ platform, width, height, style={}}) {
  return (
    <img 
        style={{ width, height, borderRadius: 5, ...style }}
        src={
            (platform == "b2w" || platform == "1") ? B2W :
            (platform == "lnx" ? LNX : platform == "useebrasil.com.br") ? LNX : 
            (platform == "mzl" || platform == "3") ? MZL :
            (platform == "sho" || platform == "5") ? SHO :
            (platform == "mlb" || platform == "2") ? MLB :
            (platform == "via" || platform == "6") ? VIA :
            (platform == "ren" || platform == "7") ? RNN :
            (platform == "amz" || platform == "8") ? AMZ :
            (platform == "nsh" || platform == "9") ? NSH :
            WZU
        } 
        alt={platform} 
    />
  )
}

export default PlatformSalesIcon
