import { httpfbnd } from "src/services";
import queryString from "query-string";

// Action Types
export const Types = {
    PICKING_LOADING: 'picking/order/priotiry/loading',
    PICKING_SET_DATA: 'picking/order/priotiry/set/data'
}

// Reducer
const initialState = {
    loading: false,
    picking_order_priority: []
};

// Action Creators
export default function reducer(state = initialState, action) {
    switch (action.type) {
        case Types.PICKING_LOADING:
            return { ...state, loading: action.payload };
        case Types.PICKING_SET_DATA:
            return { ...state, picking_data: action.payload };
      default:
        return state;
    }
}

export const Creators = {
    pickingOrderPriorityLoading: value => ({ type: Types.PICKING_LOADING, payload: value }),
    pickingOrderPrioritySetData: value => ({ type: Types.PICKING_SET_DATA, payload: value }),
    getPickingOrderPriority: (filters={}) => {
        const qry = queryString.stringify(filters, { arrayFormat: "index"})
        return new Promise( (resolve, reject) => {
            httpfbnd.get(`/logistics/picking/orders/priority?${qry}`, {timeout: 1000 * 15})
                .then(({ data }) => resolve(data))
                .catch( error => reject(error))
        })
    },
    setPickingOrderPrioriry: body => {
        return new Promise( (resolve, reject) => {
            httpfbnd.post(`/logistics/picking/orders/priority`, body, {timeout: 1000 * 60})
                .then(({ data }) => resolve(data))
                .catch( error => reject(error))
        })
    },
    
}