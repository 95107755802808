import { Avatar, Box, Card, CardContent, Grid, Typography } from '@mui/material'

function SimpleDashCard({title, titleValue, icon, iconColor, subTitleValue, subTitle, subIcon, width}) {
    return (
      <Card style={{ height: "100%" }}>
        <CardContent>
          <Grid
            container
            spacing={1}
            style={{
              justifyContent: "space-between",
            }}
          >
            <Grid item style={{ marginTop: subTitleValue ? 0 : 20 }}>
              <Typography color="textSecondary" gutterBottom variant="h6">
                {title}
              </Typography>
              <Typography color="textPrimary" variant="h3">
                {titleValue}
              </Typography>
            </Grid>
            <Grid item>
              <Avatar
                style={{
                  bgcolor: iconColor,
                  height: width === "xl" ? 56 : 40,
                  width: width === "xl" ? 56 : 40,
                }}
              >
                {icon}
              </Avatar>
            </Grid>
          </Grid>
          {subTitleValue && (
            <Box style={{ display: "flex", alignItems: "center", marginTop: 5 }}>
              {subIcon}
              <Typography
                style={{ color: iconColor, mr: 1, marginLeft: 5, fontSize: 20 }}
                variant="h6"
              >
                {subTitleValue}
              </Typography>
              <Typography
                variant="caption"
                style={{ marginLeft: 5, marginTop: 2 }}
              >
                {subTitle}
              </Typography>
            </Box>
          )}
        </CardContent>
      </Card>
    );
}

export default SimpleDashCard
