import { Backdrop, CircularProgress } from '@mui/material';
import { makeStyles } from "@mui/styles";
import { } from "@mui/system";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.weesu.dark_blue,
    background: "rgba(0,0,0,0.1)",
  },
  notBackdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.weesu.dark_blue,
  },
}));

const Loading = ({ loading, style = {}, notBackdrop=false }) => {
  const classes = useStyles();

  return (
    <Backdrop
      sx={{ ...style }}
      className={notBackdrop ? classes.notBackdrop : classes.backdrop}
      open={loading}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default Loading;