import { Button, Typography } from '@mui/material'
import { makeStyles } from "@mui/styles";
import React from 'react'

const useStyles = makeStyles(theme => ({
    iconButton: {
        top: '10px',
        width: 100,
        height: 45,
        fontSize: 14,
      },
}));

function HeaderCard({title, subtitle, onSave, iconSave, disabledSave=false, onTitle="Salvar"}) {
    const classes = useStyles()
    return (
        <div style={{display: 'flex', flexDirection: 'row', height: 60, marginBottom: 5, width: '100%'}}>
            <div style={{display: 'flex', flex: 1, flexDirection: 'column', padding: 10}}>
                { title && (
                    <Typography variant="h5">
                        {title}
                    </Typography>
                )}
                { subtitle && (
                    <Typography variant="subtitle1" style={{color: '#66788A'}}>
                        {subtitle}
                    </Typography>
                )}
            </div>
            { onSave && (
                <Button color="primary" className={classes.iconButton} disabled={disabledSave} 
                    variant="contained" onClick={onSave}>
                    {iconSave()} &nbsp;{onTitle}
                </Button>
            )}
        </div>
    )
}

export default HeaderCard
