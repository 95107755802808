import { TablePagination } from "@mui/material";
import React from "react";
import { getMobileOperatingSystem } from "src/utils/getUtils";

const Paginate = (props) => {
  const {
    rowsPerPageOptions,
    count,
    rowsPerPage,
    page,
    onPageChange,
    onRowsPerPageChange,
    colspan,
    sx
  } = props;

  function defaultLabelDisplayedRows({ from, to, count }) {
    return `${from}-${to} de ${count !== -1 ? count : `mais do que ${to}`}`;
  }

  return (
    <TablePagination
      sx={sx}
      colSpan={colspan}
      component="div"
      rowsPerPageOptions={rowsPerPageOptions}
      count={count}
      rowsPerPage={rowsPerPage}
      page={page}
      SelectProps={{
        inputProps: {
          "aria-label": "registros por página.",
        },
        native: false,
      }}
      labelRowsPerPage={
        getMobileOperatingSystem() === "unknown"
          ? "Registros por página"
          : "Por página"
      }
      labelDisplayedRows={defaultLabelDisplayedRows}
      onPageChange={onPageChange}
      onRowsPerPageChange={onRowsPerPageChange}
    />
  );
};

export default Paginate;
