import { Backdrop, LinearProgress } from "@mui/material";
import Modal from "../Modal";

const Progress = ({ loading, style = {}, value = 0, min = 0, max = 100, title="" }) => {
  const normalise = (v) => ((v - min) * 100) / (max - min);

  return (
    <Modal open={loading} width={880} height={130} style={{ zIndex: 10000 }}>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <span style={{ marginTop: "20px", marginBottom: "5px" }}>
          {`Aguarde, processando ${value} de ${max} ${title}...`}
        </span>
        <LinearProgress
          value={normalise(value)}
          variant="determinate"
          sx={{
            height: 30,
            colorPrimary: "#42B7BC",
          }}
        />
      </div>
    </Modal>
  );
};

export default Progress;
