import httpfb from '../httpfb';
import httpfbnd from '../httpfbnd';
import http from "./index";

// import jwt from 'jsonwebtoken';

const getToken = () => localStorage.getItem("accessToken");
//localStorage.getItem("access_token");

const validateToken = (token) => {
  if (token) {
    //:TODO rever jsonwebtoken
    // let dateNow = new Date();
    // const decodedToken = jwt.decode(token, { complete: true });
    // return decodedToken.payload.exp < dateNow.getTime();
    return true
  }
  return 
}

const requestInterceptor = (store) => {
  httpfbnd.interceptors.request.use((config) => {
    const token = getToken();
    if (token) {
      if (validateToken(token)) {
        config.headers.Authorization = `Bearer ${token}`;
      } else {
        window.location.pathname = "/login";
      }
    }
    return config;
  });

  httpfb.interceptors.request.use((config) => {
    const token = getToken();
    if (token) {
      if (validateToken(token)) {
        config.headers.Authorization = `Bearer ${token}`;
      } else {
        window.location.pathname = "/login";
      }
    }
    return config;
  });

  http.interceptors.request.use((config) => {
    const token = getToken();
    if (token) {
      if (validateToken(token)) {
        config.headers.Authorization = `Bearer ${token}`;
      } else {
        window.location.pathname = "/login";
      }
    }
    return config;
  });
};

export { requestInterceptor };
