import { Box, Card, CardContent, CardHeader, Divider } from '@mui/material'
import React from 'react'
import { Pie } from 'react-chartjs-2'

function PieChart({ labels, values, colorsBg, colorsBc, title, style}) {
    const data = {
        labels: labels,
        datasets: [{data: values, backgroundColor: colorsBg, borderColor: colorsBc, borderWidth: 2, hoverOffset: 4}]
    }
    
    const options = {
        responsive: true,
    }

    return (
        <Card style={{width: '100%', height: '100%'}}>
            <CardHeader title={title} />
            <Divider />
            <CardContent>
                <Box style={style}>
                    <Pie data={data} options={options}/>
                </Box>
            </CardContent>
        </Card>
    )
}

export default PieChart
