/* eslint-disable eqeqeq */
import React from "react";

import WZU from "src/assets/images/notimage.png";

import DIR from "src/assets/images/dir.png";
import MEN from "src/assets/images/men.png";
import CRR from "src/assets/images/crr.png";
import MGA from "src/assets/images/mga.png";
import MDA from "src/assets/images/mda.png";
import SPX from "src/assets/images/spx.png";
import MFL from "src/assets/images/mfu.png";
import EVI from "src/assets/images/evi.png";

function PlatformShippingIcon({ platform, width, height, style = {} }) {
  return (
    <img
      style={{ width, height, borderRadius: 5, ...style }}
      src={
        platform == 1
          ? CRR // Correios
          : platform === 2
          ? DIR // B2W Entregas (Direct)
          : platform === 3
          ? MEN // Mercado Livre (Coletas)
          : platform == 4
          ? MGA // Magazine Luiza (MagaLog)
          : platform == 5
          ? MDA // Mandaê
          : platform == 6
          ? MEN // Mercado Livre (Full)
          : platform == 7
          ? SPX // Shopee Xpress
          : platform == 8
          ? CRR // Shopee (Correios)
          : platform == 9
          ? MFL // Envio Fullfillment
          : platform == 11
          ? EVI // Envvias
          : WZU
      }
      alt={platform}
    />
  );
}

export default PlatformShippingIcon;
