import { httpfb } from "src/services";
import queryString from "query-string";

// Action Types
export const Types = {
  REPORT_LOADING: "report/loading",
  REPORT_DIVERGENCES_SET_DATA: "report/divergences/set/data",
  REPORT_DIVERGENCES_SET_PAGING: "report/divergences/set/paging",
  REPORT_DIVERGENCES_SET_FILTER: "report/divergences/set/filter",
  REPORT_DIVERGENCES_SET_FILTER_CHIP: "report/divergences/set/filter_chip",
  REPORT_DIVERGENCES_SET_ORDERS: "report/divergences/set/orders",
  REPORT_DIVERGENCES_SET_ORDER: "report/divergences/set/order",
};

// Reducer
const initialState = {
  loading: false,
  report_divergences: [],
  report_divergences_paging: { limit: 25, offset: 0, total: 0 },
  report_divergences_filters: [],
  report_divergences_filters_chip: [],
  report_divergences_orders: [],
  report_divergences_order: { column: "name", order: "asc" },
};

// Action Creators
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.REPORT_LOADING:
      return { ...state, loading: action.payload };
    case Types.REPORT_DIVERGENCES_SET_DATA:
      return { ...state, report_divergences: action.payload };
    case Types.REPORT_DIVERGENCES_SET_PAGING:
      return { ...state, report_divergences_paging: action.payload };
    case Types.REPORT_DIVERGENCES_SET_FILTER:
      return { ...state, report_divergences_filters: action.payload };
    case Types.REPORT_DIVERGENCES_SET_FILTER_CHIP:
      return { ...state, report_divergences_filters_chip: action.payload };
    case Types.REPORT_DIVERGENCES_SET_ORDERS:
      return { ...state, report_divergences_orders: action.payload };
    case Types.REPORT_DIVERGENCES_SET_ORDER:
      return { ...state, report_divergences_order: action.payload };
    default:
      return state;
  }
}

export const Creators = {
  reportLoading: (value) => ({
    type: Types.REPORT_LOADING,
    payload: value,
  }),
  reportDivergencesSetData: (value) => ({
    type: Types.REPORT_DIVERGENCES_SET_DATA,
    payload: value,
  }),
  reportDivergencesSetPaging: (report_divergences_paging) => ({
    type: Types.REPORT_DIVERGENCES_SET_PAGING,
    payload: report_divergences_paging,
  }),
  reportDivergencesSetFilters: (products_filters) => ({
    type: Types.REPORT_DIVERGENCES_SET_FILTER,
    payload: products_filters,
  }),
  reportDivergencesSetFiltersChip: (products_filters_chip) => ({
    type: Types.REPORT_DIVERGENCES_SET_FILTER_CHIP,
    payload: products_filters_chip,
  }),
  // reportDivergencesSetOrders: (orders) => ({
  //   type: Types.PRODUCTS_SET_ORDERS,
  //   payload: orders,
  // }),
  // reportDivergencesSetOrder: (order) => ({
  //   type: Types.PRODUCTS_SET_ORDER,
  //   payload: order,
  // }),
  getReportDivergences: (filters = {}) => {
    const qry = queryString
      .stringify(filters, { arrayFormat: "index" })
      .replaceAll("%5B", "[")
      .replaceAll("%5D", "]");
    return new Promise((resolve, reject) => {
      httpfb
        .get(`/reports/stock-divergences?${qry}`, {
          timeout: 1000 * 60,
        })
        .then(({ data }) => resolve(data))
        .catch((error) => reject(error));
    });
  },
};