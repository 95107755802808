import { Slide, ThemeProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import * as Sentry from "@sentry/browser";
import { SnackbarProvider } from "notistack";
import { Provider as ReduxProvider } from "react-redux";
import { useRoutes } from "react-router-dom";
import GlobalStyles from "src/pages/layouts/GlobalStyles";
import routes from "src/routes";
import { requestInterceptor } from "src/services/http/interceptors";
import store from "src/store";
import { createTheme } from "src/theme";

import { SplashScreen, Toaster } from "./components";
import { AuthConsumer, AuthProvider } from "./context/auth-context";

import "react-perfect-scrollbar/dist/css/styles.css";

requestInterceptor(store);

Sentry.init({
  dsn: "https://b81c7f0d253d437abe498506f96b46ad@glitchtip.crash.weesu.com.br/3",
});

const App = () => {
  const element = useRoutes(routes);
  const theme = createTheme();
  return (
    <ReduxProvider store={store}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <AuthProvider>
          <AuthConsumer>
            {(auth) => {
              const showSlashScreen = !auth.isInitialized;
              return (
                <ThemeProvider theme={theme}>
                  <SnackbarProvider
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    TransitionComponent={Slide}
                    maxSnack={5}
                  >
                    <GlobalStyles />
                    {showSlashScreen ? <SplashScreen /> : <>{element}</>}
                    <Toaster />
                  </SnackbarProvider>
                </ThemeProvider>
              );
            }}
          </AuthConsumer>
        </AuthProvider>
      </LocalizationProvider>
    </ReduxProvider>
  );
};

export default App;
