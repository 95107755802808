import queryString from "query-string";
import { httpfb } from "src/services";

// Action Types
export const Types = {
  PRODUCT_VARIATIONS_LOADING: "product/variations/loading",
  PRODUCT_SET_VARIATIONS: "product/set/variations",
  PRODUCT_SET_VARIATION: "product/set/variation",
  PRODUCT_SET_PAGING: "product/set/variation/paging",
  PRODUCT_SET_FILTERS_CHIP: "product/set/variation/filters/chip",
  PRODUCT_SET_FILTERS: "product/set/variation/filters",
  PRODUCT_SET_ORDERBY: "product/set/variation/orderby",
};

// Reducer
const initialState = {
  loading: false,
  product_variations: [],
  product_variation: {},
  products_variations_filters_chip: [],
  products_variations_order: { column: "owner_product_name", order: "asc" },
  products_variations_paging: {
    offset: 0,
    limit: 25,
    total: 0,
  },
  products_variations_filters: [],
};

// Action Creators
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.PRODUCT_VARIATIONS_LOADING:
      return { ...state, loading: action.payload };
    case Types.PRODUCT_SET_VARIATIONS:
      return { ...state, product_variations: action.payload };
    case Types.PRODUCT_SET_VARIATION:
      return { ...state, product_variation: action.payload };
    case Types.PRODUCT_SET_PAGING:
      return { ...state, products_variations_paging: action.payload };
    case Types.PRODUCT_SET_ORDERBY:
      return { ...state, products_variations_order: action.payload };
    case Types.PRODUCT_SET_FILTERS_CHIP:
      return { ...state, products_variations_filters_chip: action.payload };
    case Types.PRODUCT_SET_FILTERS:
      return { ...state, products_variations_filters: action.payload };
    default:
      return state;
  }
}

export const Creators = {
  productSetVariations: (product_variations) => ({
    type: Types.PRODUCT_SET_VARIATIONS,
    payload: product_variations,
  }),
  productSetVariation: (product_variations) => ({
    type: Types.PRODUCT_SET_VARIATION,
    payload: product_variations,
  }),
  productVariationLoading: (value) => ({
    type: Types.PRODUCT_VARIATIONS_LOADING,
    payload: value,
  }),
  productVariationPaging: (value) => ({
    type: Types.PRODUCT_SET_PAGING,
    payload: value,
  }),
  productVariationSetOrderBy: (value) => ({
    type: Types.PRODUCT_SET_ORDERBY,
    payload: value,
  }),
  productsVariationsSetFiltersChip: (value) => ({
    type: Types.PRODUCT_SET_FILTERS_CHIP,
    payload: value,
  }),
  productsVariationsSetFilters: (value) => ({
    type: Types.PRODUCT_SET_FILTERS,
    payload: value,
  }),
  getProdutctVariations: (ean) => {
    return new Promise((resolve, reject) => {
      httpfb
        .get(`/product-variations/search`, { params: { ean } })
        .then(({ data }) => resolve(data))
        .catch((error) => reject(error));
    });
  },
  getProdutctVariationsAll: (filters = {}) => {
    const qry = queryString
      .stringify(filters, { arrayFormat: "index" })
      .replaceAll("%5B", "[")
      .replaceAll("%5D", "]")
      .replaceAll("%20", "_")
      .replaceAll("physical%20quantity", "physical_quantity");

    return new Promise((resolve, reject) => {
      httpfb
        .get(`/product-variations/search?${qry}`, { timeout: 1000 * 50 })
        .then(({ data }) => resolve(data))
        .catch((error) => reject(error));
    });
  },
  getProdutctVariationsByID: (id) => {
    return new Promise((resolve, reject) => {
      httpfb
        .get(`/product-variations/${id}`)
        .then(({ data }) => resolve({ data }))
        .catch((error) => reject(error));
    });
  },
  getProductVariationsMapping: (variation_key, position_key) => {
    return new Promise((resolve, reject) => {
      httpfb
        .get(`/product-variations/${variation_key}/stock/positions/mapping`, {
          position_key,
        })
        .then(({ data }) => resolve({ data }))
        .catch((error) => reject(error));
    });
  },
  setProductVariationsMapping: (variation_key, position_key) => {
    return new Promise((resolve, reject) => {
      httpfb
        .post(`/product-variations/${variation_key}/stock/positions/mapping`, {
          position_key,
        })
        .then(({ data }) => resolve({ data }))
        .catch((error) => reject(error));
    });
  },
  setProductVariationsAllocation: ({
    variation_key,
    position_key,
    quantity,
  }) => {
    return new Promise((resolve, reject) => {
      httpfb
        .post(
          `/product-variations/${variation_key}/stock/positions/allocation`,
          { position_key, quantity }
        )
        .then(({ data }) => resolve({ data }))
        .catch((error) => reject(error));
    });
  },
  getStockPositons: (position_key, ignoreInventoryBlock=false) => {
    return new Promise((resolve, reject) => {
      httpfb
        .get(`/stock/positions/key/${position_key}?ignoreInventoryBlock=${ignoreInventoryBlock}`)
        .then(({ data }) => resolve({ data }))
        .catch((error) => reject(error));
    });
  },
  setStockPositonsMove: ({
    id,
    old_position_key,
    new_position_key,
    quantity,
  }) => {
    return new Promise((resolve, reject) => {
      httpfb
        .post(
          `/product-variations/${id}/stock/positions/move`,
          {
            old_position_key,
            new_position_key,
            quantity,
          },
          { timeout: 1000 * 50 }
        )
        .then(({ data }) => resolve({ data }))
        .catch((error) => reject(error));
    });
  },
  getProductVariationsPositionsMapping: (variation_key, quantity) => {
    return new Promise((resolve, reject) => {
      httpfb
        .get(
          `/product-variations/${variation_key}/stock/positions/mapping?quantity=${quantity}`
        )
        .then(({ data }) => resolve(data))
        .catch((error) => reject(error));
    });
  },
};
