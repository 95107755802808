import { makeStyles } from "@mui/styles";
import React from 'react'

const useStyles = makeStyles((theme) => ({
    separator: {
        width: 1, 
        height: 40, 
        marginLeft: 10, 
        marginRight: 10,
        marginTop: 'auto',
        marginBottom: 'auto',
        [theme.breakpoints.down("sm")]: {
            height: 30, 
            marginLeft: 5, 
            marginRight: 5,
        }
    }
}));

function CardSeparator({ hide, style={} }) {
    const classes = useStyles()
    return (
        <span 
            className={classes.separator}
            style={{ backgroundColor: !hide ? 'rgba(61,61,61, 0.1)' : null, ...style }} 
        />
    )
}

export default CardSeparator