/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import Quagga from "quagga";

function BarcodeReaderCam({ callback, width = 500, height = 500, isEan }) {
  const onInitSuccess = () => {
    Quagga.start();
  };

  function isValidBarcode(value) {
    if (!value.match(/^(\d{8}|\d{12,14})$/)) {
      return false;
    }

    const paddedValue = value.padStart(14, "0");

    let result = 0;
    for (let i = 0; i < paddedValue.length - 1; i += 1) {
      result += parseInt(paddedValue.charAt(i), 10) * (i % 2 === 0 ? 3 : 1);
    }

    return (10 - (result % 10)) % 10 === parseInt(paddedValue.charAt(13), 10);
  }

  const onDetected = (result) => {
    if (isEan) {
      if (isValidBarcode(result.codeResult.code)) {
        Quagga.offDetected(onDetected);
        callback(result.codeResult.code);
      } else {
        return;
      }
    } else {
      Quagga.offDetected(onDetected);
      callback(result.codeResult.code);
    }
  };

  useEffect(() => {
    if (
      navigator.mediaDevices &&
      typeof navigator.mediaDevices.getUserMedia === "function"
    ) {
      Quagga.init(
        {
          inputStream: {
            name: "Live",
            type: "LiveStream",
            target: document.querySelector("#video"),
            constraints: {
              width: 640,
              height: 480,
              facingMode: "environment",
            },
          },
          locator: {
            patchSize: "medium",
            halfSample: true,
          },
          numOfWorkers: 10,
          locate: true,
          decoder: {
            readers: ["ean_reader", "code_128_reader"],
          },
        },
        (err) => {
          if (err) {
            return;
          }
          onInitSuccess();
        }
      );
      Quagga.onDetected(onDetected);
      return () => Quagga.stop();
    }
    return undefined;
  }, []);

  return (
    <div style={{ width, height }}>
      <div
        id="video"
        style={{ height: "100%", width: "100%", overflow: "hidden" }}
      />
    </div>
  );
}

export default BarcodeReaderCam;
