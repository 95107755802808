import { Chip, Typography } from "@mui/material";
import { Package } from "react-feather";
import { emptyToNull } from "src/utils/getUtils";

function StockAddressView({
  data = {},
  direct = false,
  apartmentColor = "#31b7bc",
  variant = "h6",
  marginTop = 0,
  style = {},
  hideQuantity = true,
  quantity,
  marginLeft = "auto",
}) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignContent: "center",
        marginTop,
        ...style,
      }}
    >
      {direct === false ? (
        <Typography
          align="left"
          variant={variant}
          style={{
            marginLeft: "auto",
            marginTop: "auto",
            marginBottom: "auto",
          }}
        >
          {`${emptyToNull(data.stock_id) !== "" ? `AR${data?.stock_id}` : ``}`}
          {`${emptyToNull(data.block) !== "" ? ` - BL${data.block}` : ""}`}
          {`${emptyToNull(data.street) !== "" ? ` - RUA${data.street}` : ""}`}
          {`${emptyToNull(data.floor) !== "" ? ` - AD${data.floor}` : ""}`}
          {`${emptyToNull(data.tower) !== "" ? ` - P${data.tower}` : ""}`}
        </Typography>
      ) : (
        <Typography
          align="left"
          variant={variant}
          style={{
            marginLeft,
            marginTop: "auto",
            marginBottom: "auto",
          }}
        >
          {data?.split("-").slice(0, -1).join(" - ")}
        </Typography>
      )}
      {emptyToNull(data?.se_box) !== "" ? (
        <>
          <Typography
            align="left"
            color={"primary"}
            variant="h5"
            style={{ marginTop: "auto", marginBottom: "auto" }}
          >
            &nbsp;-&nbsp;
          </Typography>
          <Typography
            align="left"
            color={"primary"}
            variant="h5"
            style={{
              marginRight: "auto",
              marginTop: "auto",
              marginBottom: "auto",
              backgroundColor: "#31b7bc",
              color: "#fff",
              borderRadius: 5,
              padding: 5,
            }}
          >
            {`CX${data?.se_box}`}
          </Typography>
        </>
      ) : (
        ""
      )}
      {emptyToNull(data?.se_pallet) !== "" ? (
        <>
          <Typography
            align="left"
            color={"primary"}
            variant="h5"
            style={{ marginTop: "auto", marginBottom: "auto" }}
          >
            &nbsp;-&nbsp;
          </Typography>
          <Typography
            align="left"
            color={"primary"}
            variant="h5"
            style={{
              marginRight: "auto",
              marginTop: "auto",
              marginBottom: "auto",
              backgroundColor: "#31b7bc",
              color: "#fff",
              borderRadius: 5,
              padding: 5,
            }}
          >
            {`PL${data?.se_pallet}`}
          </Typography>
        </>
      ) : (
        ""
      )}
      {emptyToNull(data?.apartment) !== "" || direct ? (
        <>
          <Typography
            align="left"
            variant={variant}
            style={{ marginTop: "auto", marginBottom: "auto" }}
          >
            &nbsp;-&nbsp;
          </Typography>
          <Typography
            align="left"
            variant={variant}
            style={{
              marginRight: "auto",
              marginTop: "auto",
              marginBottom: "auto",
              backgroundColor: apartmentColor,
              color: "#fff",
              borderRadius: 5,
              padding: 5,
            }}
          >
            {direct === false ? `A${data.apartment}` : data.split("-").pop()}
          </Typography>
        </>
      ) : (
        ""
      )}
      {!hideQuantity && parseInt(data.physical_quantity) > 0 ? (
        <Chip
          style={{ margin: 10, fontSize: 20, marginLeft: "auto" }}
          icon={<Package />}
          variant="outlined"
          color={parseInt(data.physical_quantity) > 0 ? "primary" : "secondary"}
          label={data.physical_quantity}
        />
      ) : !hideQuantity && quantity ? (
        <Chip
          style={{
            margin: 0,
            fontSize: 15,
            marginLeft: 5,
            color: parseInt(quantity) > 0 ? "#5c5c5c" : "secondary",
            borderColor: parseInt(quantity) > 0 ? "#5c5c5c" : "secondary",
          }}
          icon={<Package size={20} />}
          variant="outlined"
          color={parseInt(quantity) > 0 ? "primary" : "secondary"}
          label={quantity}
        />
      ) : (
        ""
      )}
    </div>
  );
}

export default StockAddressView;
