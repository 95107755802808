/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { Card, Chip, Tooltip, Typography } from "@mui/material";
import moment from "moment";
import React, { useEffect } from "react";
import { AlertTriangle, Check, Package as EstoqueIcon, Package } from "react-feather";
import { ReactComponent as ArmazenIcon } from "src/assets/svg/stockicon.svg";
import { emptyToNull, getColorInventoryDays, getMobileOperatingSystem } from "src/utils/getUtils";

import useStyles from "./styles";

function ProductAddressCard({
  stock,
  new_stock_position,
  new_physical_quantity = 0,
  selStockAddress,
  oldStockAddress,
  newStockAddress,
  old_negative_physical_quantity,
  style = {},
}) {
  const classes = useStyles();

  useEffect(() => {
    if (oldStockAddress !== undefined && newStockAddress !== undefined) {
      const old_physical_quantity = stock?.stock_locations[0].positions?.filter((item) => item.key === oldStockAddress)[0].physical_quantity || 0;
      old_negative_physical_quantity(old_physical_quantity - new_physical_quantity);
    }
  }, [new_physical_quantity]);

  return (
    <Card
      className={classes.cardColumnStyle}
      style={{ marginBottom: 80, ...style }}
    >
      {stock?.stock_locations?.length > 0 ? (
        stock?.stock_locations?.map((item) => (
          <>
            <div className={classes.divArmazen}>
              <ArmazenIcon className={classes.iconArmazen} />
              <Typography
                align="left"
                variant={getMobileOperatingSystem() === "unknown" ? "h4" : "h5"}
                style={{ marginTop: "auto", marginBottom: "auto" }}
              >
                {`AR${item.id} - ${item.name}`}
              </Typography>
            </div>

            {/** Mostrar Quantidades não Alocadas */}
            {item.not_allocated_quantity > 0 && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  margin: 0,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    flexDirection: "row",
                    height: 60,
                  }}
                >
                  <AlertTriangle
                    color="#EEAD2D"
                    style={{
                      marginTop: "auto",
                      marginBottom: "auto",
                      marginLeft: "auto",
                    }}
                  />
                  <Typography
                    variant="h5"
                    style={{
                      marginLeft: 10,
                      color: "#EEAD2D",
                      marginTop: "auto",
                      marginBottom: "auto",
                    }}
                  >
                    {`Existe unidades ainda não alocadas.`}
                  </Typography>
                  <Chip
                    style={{
                      marginTop: "auto",
                      marginBottom: "auto",
                      marginRight: "auto",
                      color: "#EEAD2D",
                      marginLeft: 20,
                    }}
                    icon={<EstoqueIcon color="#EEAD2D" />}
                    label={
                      <Typography variant="h5">
                        {item.not_allocated_quantity}
                      </Typography>
                    }
                  />
                </div>
              </div>
            )}

            {/** Mostrar Lista de Endereços */}
            {oldStockAddress === undefined &&
              newStockAddress === undefined &&
              item?.positions?.map((position, iPosition) => (
                <div
                  key={position.stock_id}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    margin: 0,
                    paddingLeft: 10,
                    borderBottomWidth:
                      item?.positions?.length - 1 === iPosition ? 0 : 1,
                    borderBottomColor: "rgba(61,61,61, 0.2)",
                    borderBottomStyle: "solid",
                  }}
                >
                  <Typography
                    align="left"
                    color={new_stock_position ? "secondary" : "#121A28"}
                    variant="h5"
                    style={{ marginTop: "auto", marginBottom: "auto" }}
                  >
                    {`${emptyToNull(position.block) !== "" ? `BL${position.block}` : ""}`}
                    {`${emptyToNull(position.street) !== ""? ` - RUA${position.street}`: ""}`}
                    {`${emptyToNull(position.floor) !== "" ? ` - AD${position.floor}`: ""}`}
                    {`${emptyToNull(position.tower) ? ` - P${position.tower}` : ""}`}
                  </Typography>
                  {emptyToNull(position.se_box) !== "" ? (
                    <>
                      <Typography
                        align="left"
                        variant="h5"
                        style={{ marginTop: "auto", marginBottom: "auto" }}
                      >
                        &nbsp;-&nbsp;
                      </Typography>
                      <Tooltip title={position.id}>
                        <Typography
                          align="left"
                          variant="h5"
                          style={{
                            marginRight: "auto",
                            marginTop: "auto",
                            marginBottom: "auto",
                            backgroundColor: "#31b7bc",
                            color: "#fff",
                            borderRadius: 5,
                            padding: 5,
                          }}
                        >
                          {`CX${position.se_box}`}
                        </Typography>
                      </Tooltip>
                    </>
                  ) : (
                    ""
                  )}
                  {emptyToNull(position.se_pallet) !== "" ? (
                    <>
                      <Typography
                        align="left"
                        variant="h5"
                        style={{ marginTop: "auto", marginBottom: "auto" }}
                      >
                        &nbsp;-&nbsp;
                      </Typography>
                      <Tooltip title={position.id}>
                        <Typography
                          align="left"
                          variant="h5"
                          style={{
                            marginRight: "auto",
                            marginTop: "auto",
                            marginBottom: "auto",
                            backgroundColor: "#31b7bc",
                            color: "#fff",
                            borderRadius: 5,
                            padding: 5,
                          }}
                        >
                          {`PL${position.se_pallet}`}
                        </Typography>
                      </Tooltip>
                    </>
                  ) : (
                    ""
                  )}
                  {emptyToNull(position.apartment) !== "" ? (
                    <>
                      <Typography
                        align="left"
                        variant="h5"
                        style={{ marginTop: "auto", marginBottom: "auto" }}
                      >
                        &nbsp;-&nbsp;
                      </Typography>
                      <Tooltip title={position.id}>
                        <Typography
                          align="left"
                          color={new_stock_position ? "secondary" : "primary"}
                          variant="h5"
                          style={{
                            marginRight: "auto",
                            marginTop: "auto",
                            marginBottom: "auto",
                            backgroundColor:
                              selStockAddress !== position.key
                                ? "#31b7bc"
                                : "#3e3e3e",
                            color: "#fff",
                            borderRadius: 5,
                            padding: 5,
                          }}
                        >
                          {`A${position.apartment}`}
                        </Typography>
                      </Tooltip>
                    </>
                  ) : (
                    ""
                  )}
                  <Tooltip
                    title={
                      <ul>
                        {!position.inventory?.inventoried_at && (
                          <li>Nenhum inventário realizado</li>
                        )}
                        {position.inventory?.inventory_id && (
                          <li>ID: {position.inventory?.inventory_id}</li>
                        )}
                        {position.inventory?.inventoried_at && (
                          <li>
                            Inventariado em:{" "}
                            {moment(position.inventory?.inventoried_at).format(
                              "DD/MM/YYYY"
                            )}
                          </li>
                        )}
                        {position.inventory?.inventoried_by && (
                          <li>
                            Inventariado por:{" "}
                            {position.inventory?.inventoried_by}
                          </li>
                        )}
                        {position.inventory?.inventory_result && (
                          <li>
                            Situação do inventário:{" "}
                            {position.inventory?.inventory_result === "P"
                              ? "Aguardando"
                              : position.inventory.inventory_result === "R"
                              ? "Rejeitado"
                              : "Concluído"}
                          </li>
                        )}
                      </ul>
                    }
                  >
                    <Chip
                      style={{ margin: 10, fontSize: 20 }}
                      icon={
                        <Package
                          color={getColorInventoryDays(
                            position.inventory?.inventoried_at,
                            position.inventory?.inventory_result
                          )}
                        />
                      }
                      variant="outlined"
                      label={position.physical_quantity - new_physical_quantity}
                    />
                  </Tooltip>
                </div>
              ))}

            {/** Mostrar Endereço Antigo ou Endereços Atuais. */}
            {item?.positions?.map(
              (position, iPosition) =>
                position.key === oldStockAddress && (
                  <div
                    key={position.stock_id}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      margin: 0,
                      paddingLeft: 10,
                      borderBottomWidth:
                        item?.positions?.length - 1 === iPosition ? 0 : 1,
                      borderBottomColor: "rgba(61,61,61, 0.2)",
                      borderBottomStyle: "solid",
                    }}
                  >
                    <Typography
                      align="left"
                      color={new_stock_position ? "secondary" : "primary"}
                      variant="h5"
                      style={{ marginTop: "auto", marginBottom: "auto" }}
                    >
                      {`${
                        emptyToNull(position.block) !== ""
                          ? `BL${position.block}`
                          : ""
                      }`}
                      {`${
                        emptyToNull(position.street) !== ""
                          ? ` - RUA${position.street}`
                          : ""
                      }`}
                      {`${
                        emptyToNull(position.floor) !== ""
                          ? ` - AD${position.floor}`
                          : ""
                      }`}
                      {`${
                        emptyToNull(position.tower) !== ""
                          ? ` - P${position.tower}`
                          : ""
                      }`}
                    </Typography>
                    {emptyToNull(position.se_box) !== "" ? (
                      <>
                        <Typography
                          align="left"
                          variant="h5"
                          style={{ marginTop: "auto", marginBottom: "auto" }}
                        >
                          &nbsp;-&nbsp;
                        </Typography>
                        <Tooltip title={position.id}>
                        <Typography
                          align="left"
                          variant="h5"
                          style={{
                            marginRight: "auto",
                            marginTop: "auto",
                            marginBottom: "auto",
                            backgroundColor: "#31b7bc",
                            color: "#fff",
                            borderRadius: 5,
                            padding: 5,
                          }}
                        >
                          {`CX${position.se_box}`}
                        </Typography>
                        </Tooltip>
                      </>
                    ) : (
                      ""
                    )}
                    {emptyToNull(position.se_pallet) !== "" ? (
                      <>
                        <Typography
                          align="left"
                          variant="h5"
                          style={{ marginTop: "auto", marginBottom: "auto" }}
                        >
                          &nbsp;-&nbsp;
                        </Typography>
                        <Tooltip title={position.id}>
                          <Typography
                            align="left"
                            variant="h5"
                            style={{
                              marginRight: "auto",
                              marginTop: "auto",
                              marginBottom: "auto",
                              backgroundColor: "#31b7bc",
                              color: "#fff",
                              borderRadius: 5,
                              padding: 5,
                            }}
                          >
                            {`PL${position.se_pallet}`}
                          </Typography>
                        </Tooltip>
                      </>
                    ) : (
                      ""
                    )}
                    {emptyToNull(position.apartment) !== "" ? (
                      <>
                        <Typography
                          align="left"
                          variant="h5"
                          style={{ marginTop: "auto", marginBottom: "auto" }}
                        >
                          &nbsp;-&nbsp;
                        </Typography>
                        <Tooltip title={position.id}>
                        <Typography
                          align="left"
                          color={new_stock_position ? "secondary" : "primary"}
                          variant="h5"
                          style={{
                            marginRight: "auto",
                            marginTop: "auto",
                            marginBottom: "auto",
                            backgroundColor:
                              selStockAddress !== position.key
                                ? "#31b7bc"
                                : "#3e3e3e",
                            color: "#fff",
                            borderRadius: 5,
                            padding: 5,
                          }}
                        >
                          {`A${position.apartment}`}
                        </Typography>
                        </Tooltip>
                      </>
                    ) : (
                      ""
                    )}
                    <Tooltip
                      title={
                        <ul>
                          {!position.inventory?.inventoried_at && (
                            <li>Nenhum inventário realizado</li>
                          )}
                          {position.inventory?.inventory_id && (
                            <li>ID: {position.inventory?.inventory_id}</li>
                          )}
                          {position.inventory?.inventoried_at && (
                            <li>
                              Inventariado em:{" "}
                              {moment(
                                position.inventory?.inventoried_at
                              ).format("DD/MM/YYYY")}
                            </li>
                          )}
                          {position.inventory?.inventoried_by && (
                            <li>
                              Inventariado por:{" "}
                              {position.inventory?.inventoried_by}
                            </li>
                          )}
                          {position.inventory?.inventory_result && (
                            <li>
                              Situação do inventário:{" "}
                              {position.inventory?.inventory_result === "P"
                                ? "Aguardando"
                                : position.inventory.inventory_result === "R"
                                ? "Rejeitado"
                                : "Concluído"}
                            </li>
                          )}
                        </ul>
                      }
                    >
                      <Chip
                        style={{ margin: 10, fontSize: 20 }}
                        icon={
                          <Package
                            color={getColorInventoryDays(
                              position.inventory?.inventoried_at,
                              position.inventory?.inventory_result
                            )}
                          />
                        }
                        variant="outlined"
                        label={
                          position.physical_quantity - new_physical_quantity
                        }
                      />
                    </Tooltip>
                  </div>
                )
            )}

            {/** Mostrar Endereço Novo */}
            {item?.positions?.map(
              (position) =>
                position.key === newStockAddress && (
                  <div
                    key={position.stock_id}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      margin: 0,
                      paddingLeft: 10,
                      borderTopWidth: 2,
                      borderTopColor: "#999",
                      borderTopStyle: "dotted",
                    }}
                  >
                    <Typography
                      align="left"
                      variant="h5"
                      style={{ marginTop: "auto", marginBottom: "auto" }}
                    >
                      {`${
                        emptyToNull(position.block) !== ""
                          ? `BL${position.block}`
                          : ""
                      }`}
                      {`${
                        emptyToNull(position.street) !== ""
                          ? ` - RUA${position.street}`
                          : ""
                      }`}
                      {`${
                        emptyToNull(position.floor) !== ""
                          ? ` - AD${position.floor}`
                          : ""
                      }`}
                      {`${
                        emptyToNull(position.tower) !== ""
                          ? ` - P${position.tower}`
                          : ""
                      }`}
                    </Typography>
                    {emptyToNull(position.se_box) !== "" ? (
                      <>
                        <Typography
                          align="left"
                          variant="h5"
                          style={{ marginTop: "auto", marginBottom: "auto" }}
                        >
                          &nbsp;-&nbsp;
                        </Typography>
                        <Tooltip title={position.id}>
                        <Typography
                          align="left"
                          variant="h5"
                          style={{
                            marginRight: "auto",
                            marginTop: "auto",
                            marginBottom: "auto",
                            backgroundColor: "#31b7bc",
                            color: "#fff",
                            borderRadius: 5,
                            padding: 5,
                          }}
                        >
                          {`CX${position.se_box}`}
                        </Typography>
                        </Tooltip>
                      </>
                    ) : (
                      ""
                    )}
                    {emptyToNull(position.se_pallet) !== "" ? (
                      <>
                        <Typography
                          align="left"
                          variant="h5"
                          style={{ marginTop: "auto", marginBottom: "auto" }}
                        >
                          &nbsp;-&nbsp;
                        </Typography>
                        <Tooltip title={position.id}>
                          <Typography
                            align="left"
                            variant="h5"
                            style={{
                              marginRight: "auto",
                              marginTop: "auto",
                              marginBottom: "auto",
                              backgroundColor: "#31b7bc",
                              color: "#fff",
                              borderRadius: 5,
                              padding: 5,
                            }}
                          >
                            {`PL${position.se_pallet}`}
                          </Typography>
                        </Tooltip>
                      </>
                    ) : (
                      ""
                    )}
                    {emptyToNull(position.apartment) !== "" ? (
                      <>
                        <Typography
                          align="left"
                          variant="h5"
                          style={{ marginTop: "auto", marginBottom: "auto" }}
                        >
                          &nbsp;-&nbsp;
                        </Typography>
                        <Tooltip title={position.id}>
                        <Typography
                          align="left"
                          variant="h5"
                          style={{
                            marginRight: "auto",
                            marginTop: "auto",
                            marginBottom: "auto",
                            backgroundColor: "#31b7bc",
                            color: "#fff",
                            borderRadius: 5,
                            padding: 5,
                          }}
                        >
                          {`A${position.apartment}`}
                        </Typography>
                        </Tooltip>
                      </>
                    ) : (
                      ""
                    )}
                    <Tooltip
                      title={
                        <ul>
                          {!position.inventory?.inventoried_at && (
                            <li>Nenhum inventário realizado</li>
                          )}
                          {position.inventory?.inventory_id && (
                            <li>ID: {position.inventory?.inventory_id}</li>
                          )}
                          {position.inventory?.inventoried_at && (
                            <li>
                              Inventariado em:{" "}
                              {moment(
                                position.inventory?.inventoried_at
                              ).format("DD/MM/YYYY")}
                            </li>
                          )}
                          {position.inventory?.inventoried_by && (
                            <li>
                              Inventariado por:{" "}
                              {position.inventory?.inventoried_by}
                            </li>
                          )}
                          {position.inventory?.inventory_result && (
                            <li>
                              Situação do inventário:{" "}
                              {position.inventory?.inventory_result === "P"
                                ? "Aguardando"
                                : position.inventory.inventory_result === "R"
                                ? "Rejeitado"
                                : "Concluído"}
                            </li>
                          )}
                        </ul>
                      }
                    >
                      <Chip
                        style={{ margin: 10, fontSize: 20 }}
                        icon={
                          <Package
                            color={getColorInventoryDays(
                              position.inventory?.inventoried_at,
                              position.inventory?.inventory_result
                            )}
                          />
                        }
                        variant="outlined"
                        label={
                          position.physical_quantity + new_physical_quantity
                        }
                      />
                    </Tooltip>
                  </div>
                )
            )}
          </>
        ))
      ) : (
        <div
          className={classes.divArmazen}
          style={{ backgroundColor: "white" }}
        >
          <ArmazenIcon
            className={classes.iconArmazen}
            style={{ marginLeft: "auto" }}
          />
          <Typography
            align="left"
            variant={getMobileOperatingSystem() === "unknown" ? "h4" : "h5"}
            style={{
              marginTop: "auto",
              marginBottom: "auto",
              marginRight: "auto",
              marginLeft: 10,
            }}
          >
            Não existe nenhum endereço cadastrado para este produto!
          </Typography>
        </div>
      )}
    </Card>
  );
}

export default ProductAddressCard;
