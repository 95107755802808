import { IconButton, InputAdornment } from '@mui/material'
import { Add, Remove } from '@mui/icons-material'
import React from 'react'
import { getMobileOperatingSystem } from 'src/utils/getUtils'
import InputText from '../InputText'

function InputPlusAndMinusNumber({ value, onChange, onMinus, onPlus, onBlur, style={}, onKeyDown }) {
  return (
    <InputText 
        autoFocus
        style={{}}
        value={value}
        onChange={onChange}
        onKeyDown={onKeyDown}
        inputMode={getMobileOperatingSystem() === 'iOS' ? "text" : "numeric"}
        onBlur={onBlur}
        InputProps={{
            style:{
                textAlign: 'center',
                fontWeight: 'bold',
                fontSize: 30,
                ...style
            },
            startAdornment: (
                <InputAdornment position="start">
                    <IconButton
                        aria-label="Remove um item"
                        onClick={onMinus}
                    >
                        <Remove />
                    </IconButton>
                </InputAdornment>
            ),
            endAdornment: (
                <InputAdornment position="end">
                    <IconButton
                        aria-label="Adiciona um item"
                        onClick={onPlus}
                    >
                        <Add />
                    </IconButton>
                </InputAdornment>
            )
        }}
    />
  )
}

export default InputPlusAndMinusNumber