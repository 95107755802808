import { baseUrl } from "./http";

const url = `${baseUrl()}`;

const AuthApi = {
  signIn: async (email, password, applicationId = "2") => {
    return new Promise((resolve, reject) => {
      fetch(`${url}/v2/core/auth/signin`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password, applicationId }),
      })
        .then((res) => res.json())
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  signUp: async (customer, user, applicationId = "2") => {
    return new Promise((resolve, reject) => {
      fetch(`${url}/v2/core/auth/signup`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ customer, user, applicationId }),
      })
        .then((res) => res.json())
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  recoveryPassword: async (email) => {
    return new Promise((resolve, reject) => {
      fetch(`${url}/v2/core/auth/recoverypassword`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      })
        .then((res) => res.json())
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  resetPassword: async (token, password) => {
    return new Promise((resolve, reject) => {
      fetch(`${url}/v2/core/auth/resetpassword`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ token, password }),
      })
        .then((res) => res.json())
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getAuthenticated: async (accessToken) => {
    return new Promise((resolve, reject) => {
      fetch(`${url}/v2/core/auth/authenticated`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

export default AuthApi;