import queryString from "query-string";
import { httpfbnd } from "src/services";

// Action Types
export const Types = {
  STOCK_TRANSFER_LOADING: "stock/transfer/loading",
  STOCK_TRANSFER_SET_TRANSFERS: "stock/transfer/set/transfers",
  STOCK_TRANSFER_SET_TRANSFERS_PAGINATE: "stock/transfer/set/transfers/paginate",
  STOCK_TRANSFER_SET_STATUS: "stock/transfer/set/status",
  STOCK_TRANSFER_SET_TRANSFER: "stock/transfer/set/transfer",
  STOCK_TRANSFER_SET_TRANSFERS_ITEMS: "stock/transfer/set/transfers/items",
  STOCK_TRANSFER_SET_TRANSFERS_ITEMS_PAGINATE: "stock/transfer/set/transfers/items/paginate",
  STOCK_TRANSFER_SET_TRANSFERS_ITEMS_FILTERS: "stock/transfer/set/transfers/items/filters",
  STOCK_TRANSFER_SET_TRANSFERS_ITEMS_FILTERS_CHIP: "stock/transfer/set/transfers/items/filters/chip",
  STOCK_TRANSFER_SET_TRANSFERS_PENDING: "stock/transfer/set/transfers/pending",
  STOCK_TRANSFER_SET_TRANSFERS_PENDING_PAGINATE: "stock/transfer/set/transfers/pending/paginate",
  STOCK_TRANSFER_SET_TRANSFERS_PENDING_DETAIL: "stock/transfer/set/transfers/pending/detail",
};

// Reducer
const initialState = {
  loading: false,
  transfers: [],
  transfer: {},
  transfers_paginate: {
    page: 0,
    size: 20,
    count: 0,
  },
  transfers_status: [],
  transfer_items: [],
  transfer_items_paginate: {
    page: 0,
    size: 20,
    count: 0,
  },
  transfer_items_filters: [],
  transfer_items_filters_chip: [],
  transfer_pending: [],
  transfer_pending_paginate: {
    page: 0,
    size: 20,
    count: 0,
  },
  transfer_pending_detail: {},
};

// Action Creators
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.STOCK_TRANSFER_LOADING:
      return { ...state, loading: action.payload };
    case Types.STOCK_TRANSFER_SET_TRANSFERS:
      return { ...state, transfers: action.payload };
    case Types.STOCK_TRANSFER_SET_TRANSFERS_PAGINATE:
      return { ...state, transfers_paginate: action.payload };
    case Types.STOCK_TRANSFER_SET_STATUS:
      return { ...state, transfers_status: action.payload };
    case Types.STOCK_TRANSFER_SET_TRANSFER:
      return { ...state, transfer: action.payload };
    case Types.STOCK_TRANSFER_SET_TRANSFERS_ITEMS:
      return { ...state, transfer_items: action.payload };
    case Types.STOCK_TRANSFER_SET_TRANSFERS_ITEMS_PAGINATE:
      return { ...state, transfer_items_paginate: action.payload };
    case Types.STOCK_TRANSFER_SET_TRANSFERS_ITEMS_FILTERS:
      return { ...state, transfer_items_filters: action.payload };
    case Types.STOCK_TRANSFER_SET_TRANSFERS_ITEMS_FILTERS_CHIP:
      return { ...state, transfer_items_filters_chip: action.payload };
    case Types.STOCK_TRANSFER_SET_TRANSFERS_PENDING:
      return { ...state, transfer_pending: action.payload };
    case Types.STOCK_TRANSFER_SET_TRANSFERS_PENDING_PAGINATE:
      return { ...state, transfer_pending_paginate: action.payload };
    case Types.STOCK_TRANSFER_SET_TRANSFERS_PENDING_DETAIL: 
      return { ...state, transfer_pending_detail: action.payload };
    default:
      return state;
  }
}

export const Creators = {
  sTransferLoading: (value) => ({
    type: Types.STOCK_TRANSFER_LOADING,
    payload: value,
  }),
  sTransferSetTransfers: (value) => ({
    type: Types.STOCK_TRANSFER_SET_TRANSFERS,
    payload: value,
  }),
  sTransferSetTransfersPaginate: (value) => ({
    type: Types.STOCK_TRANSFER_SET_TRANSFERS_PAGINATE,
    payload: value,
  }),
  sTransferSetStatus: (value) => ({
    type: Types.STOCK_TRANSFER_SET_STATUS,
    payload: value,
  }),
  sTransferSet: (value) => ({
    type: Types.STOCK_TRANSFER_SET_TRANSFER,
    payload: value,
  }),
  sTransferSetTransfersItems: (value) => ({
    type: Types.STOCK_TRANSFER_SET_TRANSFERS_ITEMS,
    payload: value,
  }),
  sTransferSetTransfersItemsPaginate: (value) => ({
    type: Types.STOCK_TRANSFER_SET_TRANSFERS_ITEMS_PAGINATE,
    payload: value,
  }),
  sTransferSetTransfersItemsFilters: (value) => ({
    type: Types.STOCK_TRANSFER_SET_TRANSFERS_ITEMS_FILTERS,
    payload: value,
  }),
  sTransferSetTransfersItemsFiltersChip: (value) => ({
    type: Types.STOCK_TRANSFER_SET_TRANSFERS_ITEMS_FILTERS_CHIP,
    payload: value,
  }),
  sTransferSetTransfersPending: (value) => ({
    type: Types.STOCK_TRANSFER_SET_TRANSFERS_PENDING,
    payload: value,
  }),
  sTransferSetTransfersPendingPaginate: (value) => ({
    type: Types.STOCK_TRANSFER_SET_TRANSFERS_PENDING_PAGINATE,
    payload: value,
  }),
  sTransferSetTransfersPendingDetail: (value) => ({
    type: Types.STOCK_TRANSFER_SET_TRANSFERS_PENDING_DETAIL,
    payload: value,
  }),
  getAllTransfers: (filters) => {
    const qry = queryString.stringify(filters, { arrayFormat: "index" });
    return (dispatch) => {
      dispatch(Creators.sTransferLoading(true));
      httpfbnd
        .get(`/transfer/stock?${qry}`, { timeout: 1000 * 15 })
        .then(({ data: { count, rows, page, size } }) => {
          dispatch([
            Creators.sTransferSetTransfers(rows),
            Creators.sTransferSetTransfersPaginate({ count, page, size }),
            Creators.sTransferLoading(false),
          ]);
        })
        .catch((error) => {
          dispatch(Creators.sTransferLoading(false));
        });
    };
  },
  getAllTransfersStatus: () => (dispatch) => {
    httpfbnd
      .get(`/transfer/stock/status`, { timeout: 1000 * 15 })
      .then(({ data }) => dispatch(Creators.sTransferSetStatus(data.rows)));
  },
  getTransferById: (id) => (dispatch) => {
    httpfbnd
      .get(`/transfer/stock/${id}`, { timeout: 1000 * 15 })
      .then(({ data }) => dispatch(Creators.sTransferSet(data)));
  },
  createTransfer: (data, callback) => (dispatch) => {
    dispatch(Creators.sTransferLoading(true));
    httpfbnd
      .post(`/transfer/stock`, data, { timeout: 1000 * 15 })
      .then(({ data: { id } }) => {
        dispatch([
          Creators.sTransferLoading(false),
          Creators.getTransferById(id),
        ]);
        callback(id);
      })
      .catch((error) => {
        dispatch(Creators.sTransferLoading(false));
      });
  },
  getAllTransfersItems: (filters) => {
    const qry = queryString
      .stringify(filters, { arrayFormat: "index" })
      .replaceAll("%5B", "[")
      .replaceAll("%5D", "]")
      .replaceAll("%20", "_");

    return (dispatch) => {
      dispatch(Creators.sTransferLoading(true));
      httpfbnd
        .get(`/transfer/stock/items?${qry}`, { timeout: 1000 * 15 })
        .then(({ data: { count, rows, page, size, smart_filters } }) => {
          dispatch([
            Creators.sTransferSetTransfersItems(rows),
            Creators.sTransferSetTransfersItemsPaginate({ count, page, size }),
            Creators.sTransferSetTransfersItemsFilters(smart_filters),
            Creators.sTransferLoading(false),
          ]);
        })
        .catch((error) => {
          dispatch(Creators.sTransferLoading(false));
        });
    };
  },
  createTransferItems: (data, transfer_id, callback) => (dispatch) => {
    if (data.length === 0) return;
    dispatch(Creators.sTransferLoading(true));
    httpfbnd
      .post(`/transfer/stock/add/items/${transfer_id}`, data, {
        timeout: 1000 * 15,
      })
      .then(({ data: { ids } }) => {
        dispatch([
          Creators.sTransferLoading(false),
          Creators.sTransferSetTransfersItemsPaginate({
            page: 0,
            size: 15,
            count: 0,
          }),
        ]);
        callback(ids, null);
      })
      .catch((error) => {
        dispatch(Creators.sTransferLoading(false));
        callback(null, error);
      });
  },
  updateTransferItems: (data, callback) => (dispatch) => {
    if (data.length === 0) return;
    dispatch(Creators.sTransferLoading(true));
    httpfbnd
      .patch(`/transfer/stock/items`, data, { timeout: 1000 * 15 })
      .then(({ data: { ids } }) => {
        dispatch([
          Creators.sTransferLoading(false),
          Creators.sTransferSetTransfersItemsPaginate({
            page: 0,
            size: 15,
            count: 0,
          }),
        ]);
        callback(ids, null);
      })
      .catch((error) => {
        dispatch(Creators.sTransferLoading(false));
        callback(null, error);
      });
  },
  approveTransferItems: (data, callback) => (dispatch) => {
    if (data.length === 0) return;
    dispatch(Creators.sTransferLoading(true));
    httpfbnd
      .patch(`/transfer/stock/approve/items`, data, { timeout: 1000 * 15 })
      .then(({ data: { ids } }) => {
        dispatch([
          Creators.sTransferLoading(false),
          Creators.sTransferSetTransfersItemsPaginate({
            page: 0,
            size: 15,
            count: 0,
          }),
        ]);
        callback(ids, null);
      })
      .catch((error) => {
        dispatch(Creators.sTransferLoading(false));
        callback(null, error);
      });
  },
  deleteTransferItems: (data, callback) => (dispatch) => {
    if (data.length === 0) return;
    dispatch(Creators.sTransferLoading(true));
    httpfbnd
      .patch(`/transfer/stock/delete/items`, data, { timeout: 1000 * 15 })
      .then(({ data: { ids } }) => {
        dispatch([
          Creators.sTransferLoading(false),
          Creators.sTransferSetTransfersItemsPaginate({
            page: 0,
            size: 15,
            count: 0,
          }),
        ]);
        callback(ids, null);
      })
      .catch((error) => {
        dispatch(Creators.sTransferLoading(false));
        callback(null, error);
      });
  },
  getAllTransfersPending: (filters) => {
    const qry = queryString.stringify(filters, { arrayFormat: "index" });
    return (dispatch) => {
      dispatch(Creators.sTransferLoading(true));
      httpfbnd
        .get(`/transfer/stock/pending?${qry}`, { timeout: 1000 * 15 })
        .then(({ data: { count, rows, page, size } }) => {
          dispatch([
            Creators.sTransferSetTransfersPending(rows),
            Creators.sTransferSetTransfersPendingPaginate({
              count,
              page,
              size,
            }),
            Creators.sTransferLoading(false),
          ]);
        })
        .catch((error) => {
          dispatch(Creators.sTransferLoading(false));
        });
    };
  },
  getAllTransfersPendingById: (id) => {
    return (dispatch) => {
      dispatch(Creators.sTransferLoading(true));
      httpfbnd
        .get(`/transfer/stock/pending/${id}`, { timeout: 1000 * 15 })
        .then(({ data }) => {
          dispatch([
            Creators.sTransferSetTransfersPendingDetail(data),
            Creators.sTransferLoading(false),
          ]);
        })
        .catch((error) => {
          dispatch(Creators.sTransferLoading(false));
        });
    };
  },
  updateTransferItemQuantity: (id, quantity) => {
    return new Promise((resolve, reject) => {
      httpfbnd
        .patch(`/transfer/stock/item/${id}/${quantity}`, {
          timeout: 1000 * 15,
        })
        .then(() => resolve())
        .catch((error) => {
          reject(error);
        });
    });
  },
};
