import { httpfbnd } from "src/services";

// Action Types
export const Types = {
  DASHBOARD_LOADING: "dashboard/loading",
  DASHBOARD_WORKED_HOURS: "dashboard/worked/hours",
  DASHBOARD_PICKED_USERS: "dashboard/picked/users",
  DASHBOARD_PACKED_USERS: "dashboard/packed/users",
  DASHBOARD_RESUME_CARDS: "dashboard/resume/cards",
  DASHBOARD_ALLOCATED_USERS: "dashboard/allocated/users",
  DASHBOARD_INVENTORIED_USERS: "dashboard/inventoried/users",
};

// Reducer
const initialState = {
  loading: false,
  picked_users: [],
  packed_users: [],
  worked_hours: [],
  resume_cards: [],
  allocated_users: [],
  inventoried_users: [],
};

// Action Creators
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.DASHBOARD_LOADING:
      return { ...state, loading: action.payload };
    case Types.DASHBOARD_WORKED_HOURS:
      return { ...state, worked_hours: action.payload };
    case Types.DASHBOARD_PICKED_USERS:
      return { ...state, picked_users: action.payload };
    case Types.DASHBOARD_PACKED_USERS:
      return { ...state, packed_users: action.payload };
    case Types.DASHBOARD_RESUME_CARDS:
      return { ...state, resume_cards: action.payload };
    case Types.DASHBOARD_ALLOCATED_USERS:
      return { ...state, allocated_users: action.payload };
    case Types.DASHBOARD_INVENTORIED_USERS:
      return { ...state, inventoried_users: action.payload };
    default:
      return state;
  }
}

export const Creators = {
  dashboardLoading: (value) => ({
    type: Types.DASHBOARD_LOADING,
    payload: value,
  }),
  dashboardPickedUsers: (value) => ({
    type: Types.DASHBOARD_PICKED_USERS,
    payload: value,
  }),
  dashboardPackedUsers: (value) => ({
    type: Types.DASHBOARD_PACKED_USERS,
    payload: value,
  }),
  dashboardAllocatedUsers: (value) => ({
    type: Types.DASHBOARD_ALLOCATED_USERS,
    payload: value,
  }),
  dashboardInventoriedUsers: (value) => ({
    type: Types.DASHBOARD_INVENTORIED_USERS,
    payload: value,
  }),
  dashboardWorkedHours: (value) => ({
    type: Types.DASHBOARD_WORKED_HOURS,
    payload: value,
  }),
  dashboardResumeCards: (value) => ({
    type: Types.DASHBOARD_RESUME_CARDS,
    payload: value,
  }),
  getPickedHours: () => {
    return new Promise((resolve, reject) => {
      httpfbnd
        .get(`/dashboard/logistics/order/picked/hours`, {
          timeout: 1000 * 15,
        })
        .then(({ data }) => resolve(data))
        .catch((error) => reject(error));
    });
  },
  getPackedHours: () => {
    return new Promise((resolve, reject) => {
      httpfbnd
        .get(`/dashboard/logistics/order/packed/hours`, {
          timeout: 1000 * 15,
        })
        .then(({ data }) => resolve(data))
        .catch((error) => reject(error));
    });
  },
  getPickedUsers: () => {
    return new Promise((resolve, reject) => {
      httpfbnd
        .get(`/dashboard/logistics/order/picked/users`, {
          timeout: 1000 * 15,
        })
        .then(({ data }) => resolve(data))
        .catch((error) => reject(error));
    });
  },
  getPackedUsers: () => {
    return new Promise((resolve, reject) => {
      httpfbnd
        .get(`/dashboard/logistics/order/packed/users`, {
          timeout: 1000 * 15,
        })
        .then(({ data }) => resolve(data))
        .catch((error) => reject(error));
    });
  },
  getAllocatedUsers: () => {
    return new Promise((resolve, reject) => {
      httpfbnd
        .get(`/dashboard/logistics/stock/allocated/users`, {
          timeout: 1000 * 15,
        })
        .then(({ data }) => resolve(data))
        .catch((error) => reject(error));
    });
  },
  getInventoriedUsers: () => {
    return new Promise((resolve, reject) => {
      httpfbnd
        .get(`/dashboard/logistics/stock/inventoried/users`, {
          timeout: 1000 * 15,
        })
        .then(({ data }) => resolve(data))
        .catch((error) => reject(error));
    });
  },
  getResumeCards: () => {
    return new Promise((resolve, reject) => {
      httpfbnd
        .get(`/dashboard/logistics/order/resume/cards`, {
          timeout: 1000 * 15,
        })
        .then(({ data }) => resolve(data))
        .catch((error) => reject(error));
    });
  },
  getResumeAll: () => {
    return new Promise((resolve, reject) => {
      httpfbnd
        .get(`/dashboard/logistics/order/resume/all`, {
          timeout: 1000 * 15,
        })
        .then(({ data }) => resolve(data))
        .catch((error) => reject(error));
    });
  },
};
