import { Button, DialogActions, DialogContent, DialogContentText, Dialog as DialogMaterial, DialogTitle } from '@mui/material';

const Dialog = (props) => {
    return (
      <DialogMaterial
        autofocus
        fullWidth={props.fullWidth}
        open={props.visible}
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{ fontWeight: "bold", fontSize: 18 }}
        >
          {props.title}
        </DialogTitle>
        <DialogContent>
          {props.renderAsHtml && (
            <div dangerouslySetInnerHTML={{ __html: props.contenttext }} />
          )}
          {props.contenttext && (
            <DialogContentText>{props.contenttext}</DialogContentText>
          )}
          {props.children}
        </DialogContent>
        <DialogActions sx={{ flexWrap: "noWrap" }}>
          {props.buttons.map((buttons, index) => {
            return (
              <Button
                {...buttons.otherParams}
                sx={{ ...buttons.style }}
                disabled={buttons.disabled}
                onClick={() => buttons.onClick()}
                key={index}
              >
                {buttons.icon}&nbsp;{buttons.title}
              </Button>
            );
          })}
        </DialogActions>
      </DialogMaterial>
    );
}

export default Dialog;