import styled from "@emotion/styled";
import { Tabs as AntTabs } from "@mui/material";

const Tabs = styled((props) => <AntTabs disableripple="true" {...props} />)(
  ({ theme }) => ({
    textTransform: "none !important",
    "& .MuiTabs-indicator": {
      backgroundColor: theme.palette.weesu.light_blue,
      color: theme.palette.weesu.dark_blue,
      height: 4,
    },
    "& .Mui-selected": {},
    "& .MuiTabs-indicatorSpan": {},
  })
);

export default Tabs