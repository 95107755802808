import styled from "@emotion/styled";
import { StepLabel as AntStepLabel } from "@mui/material";

const StepLabel = styled((props) => <AntStepLabel {...props} />)(
  ({ theme }) => ({
    "& .Mui-active": {
      color: `${theme.palette.weesu.light_blue} !important`,
    },
    "& .Mui-completed": {
      color: `${theme.palette.weesu.dark_blue} !important`,
    },
    "& 	.Mui-disabled": {
      color: `${theme.palette.weesu.dark_blue} !important`,
    },
  })
);

export default StepLabel;
