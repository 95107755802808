import { lazy, Suspense } from "react";
import { Navigate, Outlet } from "react-router-dom";
import DashboardLayout from "src/pages/layouts/DashboardLayout";
import { modules } from './modules.js';


const Login = lazy(() => import("src/pages/login/index"));
const Home = lazy(() => import("src/pages/home/index"));
const MapeamentoProdutos = lazy(() =>
import("src/pages/estoque/produtos/mapeamento/index")
);
const AlocacaoProdutos = lazy(() =>
import("src/pages/estoque/produtos/alocacao/index")
);
const SeparacaoProdutos = lazy(() =>
import("src/pages/expedicao/separacao/index")
);
const SeparacaoCaixa = lazy(() =>
import("src/pages/expedicao/separacao/caixa")
);
const SeparacaoOperacao = lazy(() =>
import("src/pages/expedicao/separacao/separacao")
);
const Conferencia = lazy(() => import("src/pages/expedicao/conferencia"));
const ListagemProdutos = lazy(() =>
import("src/pages/estoque/produtos/listagem/index")
);
const VisualizarProduto = lazy(() =>
import("src/pages/estoque/produtos/visualizacao/index")
);
const TransfenciaProduto = lazy(() =>
import("src/pages/estoque/produtos/transferencia/index")
);
const Transferindo = lazy(() => import("src/pages/estoque/produtos/transferir/realizar"));
const CaixaSeparacao = lazy(() =>
  import("src/pages/cadastros/caixa_de_separacao/index")
);
const Embalagens = lazy(() => import("src/pages/cadastros/embalagens/index"));
const EditEmbalagens = lazy(() =>
  import("src/pages/cadastros/embalagens/edit")
);
const MovmentacaoEstoque = lazy(() =>
  import("src/pages/estoque/produtos/movimentacao")
);
const Usuarios = lazy(() => import("src/pages/sistema/usuarios"));
const EditUsuarios = lazy(() => import("src/pages/sistema/usuarios/edit"));
const VisualizarLogs = lazy(() => import("src/pages/sistema/visualizar_logs"));
const RelacaoDeVolumes = lazy(() =>
  import("src/pages/expedicao/relacao_volumes")
);
const ExpedicoesRealizadas = lazy(() =>
  import("src/pages/expedicao/expedicoes_realizadas")
);
const PesquisaRapida = lazy(() =>
  import("src/pages/estoque/produtos/pesquisa_rapida")
);
const PrioridadeSeparacao = lazy(() =>
  import("src/pages/sistema/prioridade_separacao")
);
const SolicitaTransferencia = lazy(() =>
  import("src/pages/estoque/produtos/transferencia/solicita")
);
const InventarioLivre = lazy(() =>
  import("src/pages/estoque/produtos/inventario_livre")
);
const InventariarList = lazy(() =>
  import("src/pages/estoque/produtos/inventariar")
);
const InventarioLista = lazy(() =>
  import("src/pages/estoque/produtos/inventario")
);
const InventarioEdit = lazy(() =>
  import("src/pages/estoque/produtos/inventario/edit")
);
const Inventariando = lazy(() =>
  import("src/pages/estoque/produtos/inventariar/realizar")
);
const DivergenciaEstoque = lazy(() =>
  import("src/pages/relatorios/divergencia_estoque/index")
);
const EnderecoZerado = lazy(() =>
  import("src/pages/relatorios/enderecos_zerado/index")
);
const Enderecos = lazy(() => import("src/pages/cadastros/enderecos/index"));
const ProdutosCurvaA = lazy(() => import("./pages/relatorios/curva_a"));
const Transferir = lazy(() => import("src/pages/estoque/produtos/transferir"));

const routes = [
  { path: "/", element: <Navigate to="/login" /> },
  { path: "/login", element: <Login /> },
  { path: "*", element: <Navigate to="/login" /> },
  {
    path: "/app",
    element: (
      <DashboardLayout>
        <Suspense>
          <Outlet />
        </Suspense>
      </DashboardLayout>
    ),
    children: [
      { path: "", element: <Navigate to="home" /> },
      { path: "home", element: <Home /> },
      { path: "cadastro/caixa_separacao", element: <CaixaSeparacao /> },
      { path: "cadastro/embalagens", element: <Embalagens /> },
      { path: "cadastro/embalagens/editar/:id", element: <EditEmbalagens /> },
      { path: "cadastro/enderecos", element: <Enderecos /> },
      { path: "estoque/pesquisa/rapida", element: <PesquisaRapida /> },
      { path: "estoque/mapeamento/produtos", element: <MapeamentoProdutos /> },
      { path: "estoque/alocacao/produtos", element: <AlocacaoProdutos /> },
      {
        path: "estoque/alocacao/produtos/:callback/:ean_produto",
        element: <AlocacaoProdutos />,
      },
      { path: "estoque/listagem/produtos", element: <ListagemProdutos /> },
      {
        path: "estoque/visualizar/produto/:id",
        element: <VisualizarProduto />,
      },
      {
        path: "estoque/movimentacao/produtos",
        element: <MovmentacaoEstoque />,
      },
      {
        path: "estoque/transferencia/produtos",
        element: <TransfenciaProduto />,
      },
      {
        path: "estoque/transferencia/produtos/:id",
        element: <SolicitaTransferencia />,
      },
      { path: "estoque/inventario/livre", element: <InventarioLivre /> },
      { path: "estoque/inventariar/lista", element: <InventariarList /> },
      {
        path: "estoque/inventariar/:inventory_id/endereco/:id",
        element: <Inventariando />,
      },
      {
        path: "estoque/inventariar/:inventory_id/endereco/:id",
        element: <Inventariando />,
      },
      { path: "estoque/transferir", element: <Transferir /> },
      { path: "estoque/transferir/:id", element: <Transferindo /> },
      { path: "estoque/inventario/lista", element: <InventarioLista /> },
      { path: "estoque/inventario/editar/:id", element: <InventarioEdit /> },
      { path: "expedicao/separacao", element: <SeparacaoProdutos /> },
      { path: "expedicao/separacao_caixa/:id", element: <SeparacaoCaixa /> },
      {
        path: "expedicao/separacao_operacao/:id",
        element: <SeparacaoOperacao />,
      },
      { path: "expedicao/relacao_de_volumes", element: <RelacaoDeVolumes /> },
      {
        path: "expedicao/expedicoes_realizadas",
        element: <ExpedicoesRealizadas />,
      },
      { path: "expedicao/conferencia", element: <Conferencia /> },
      {
        path: "relatorios/divergencia_estoque",
        element: <DivergenciaEstoque />,
      },
      { path: "relatorios/endereco_zerado", element: <EnderecoZerado /> },
      { path: "relatorios/curva_a", element: <ProdutosCurvaA /> },
      { path: "sistema/usuarios", element: <Usuarios /> },
      { path: "sistema/usuarios/cadastro/:id", element: <EditUsuarios /> },
      { path: "sistema/visualizar/logs", element: <VisualizarLogs /> },
      {
        path: "sistema/prioridade-separacao",
        element: <PrioridadeSeparacao />,
      },
      { path: "logout", element: <Navigate to="/" /> },
      { path: "*", element: <Navigate to="/login" /> },
    ],
  },
];

export default routes;
