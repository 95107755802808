import { TextField, InputAdornment, ButtonBase } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Modal } from "src/components";
import { Search as SearchIcon } from "@mui/icons-material";

const useStyles = makeStyles({
  textField: {
    width: "100%",
    maxWidth: "100%",
  },
});

function InputSearch({
  modal,
  openmodal,
  label,
  values = { id: 0, label: "" },
  children,
  inputProps,
  modalProps,
}) {
  const classes = useStyles();
  return (
    <>
      <Modal {...modalProps} open={modal}>
        {children}
      </Modal>
      <TextField
        {...inputProps}
        className={classes.textField}
        label={label}
        value={values.label}
        InputLabelProps={{ shrink: true }}
        variant="outlined"
        margin="dense"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" style={{}}>
              {values.id > 0 ? values.id : ""}
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment
              position="end"
              style={{}}
              onClick={() => openmodal()}
            >
              <ButtonBase>
                <SearchIcon />
              </ButtonBase>
            </InputAdornment>
          ),
        }}
      />
    </>
  );
}

export default InputSearch;
