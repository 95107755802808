import styled from "@emotion/styled";
import { alpha, Switch } from "@mui/material";

export default styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase": {
    "&.Mui-checked": {
      color: theme.palette.weesu.light_blue,
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: alpha(theme.palette.weesu.light_blue, 0.6),
      },
    },
  },
}));
