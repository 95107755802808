import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  textField: {
      width: '100%',
      maxWidth: '100%',
  },
})

function InputDate({
  fullWidth = false,
  label,
  value,
  onChange,
  disabled = false,
  style={}
}) {
  const classes = useStyles()
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DatePicker
        className={classes.textField}
        fullWidth={fullWidth}
        disabled={disabled}
        label={label}
        value={value}
        onChange={onChange}
        format="DD/MM/YYYY"
        margin="dense"
        orientation="landscape"
        clearLabel="Limpar"
        clearable={true}
        cancelLabel="Cancelar"
        showTodayButton={true}
        todayLabel="Hoje"
        autoOk={true}
        InputLabelProps={{ shrink: true }}
        inputProps={{
          margin: "dense",
        }}
        inputVariant="outlined"
        invalidDateMessage=""
        style={{ ...style }}
      />
    </LocalizationProvider>
  );
}

export default InputDate;