import { Grid } from "@mui/material";
import * as Sentry from "@sentry/browser";
import { useSnackbar } from "notistack";
import React, { useCallback, useEffect, useState } from "react";
import { CheckSquare, XSquare } from "react-feather";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Dialog from "src/components/ui/Dialog";
import InputSelect from "src/components/ui/InputSelect";
import InputText from "src/components/ui/InputText";
import Loading from "src/components/ui/Loading";
import { Creators as CreatorsInventory } from "src/store/ducks/inventory";
import { Creators as CreatorsStock } from "src/store/ducks/stock";
import { getErrors } from "src/utils/getUtils";

function CreateInventoryVariations({
  open,
  close,
  title = "",
  variations_id = [],
}) {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const {
    storages,
    types,
    loading: loadingStock,
  } = useSelector((state) => state.stock);
  const { loading: loadingInventory } = useSelector((state) => state.inventory);
  const [inventory, setInventory] = useState({
    storage_id: 0,
    title: "",
    type_id: 3,
  });

  useEffect(() => onOpenDialog(), [open]);

  const onOpenDialog = useCallback(() => {
    dispatch(CreatorsStock.stockLoading(true));
    CreatorsStock.getStorageList()
      .then((data) => {
        dispatch([
          CreatorsStock.stockLoading(false),
          CreatorsStock.stockSetStorage(data),
        ]);
        setInventory({
          open: true,
          title: "",
          type_id: 3,
          storage_id: "",
        });
      })
      .catch((error) => {
        Sentry.captureException(error);
        dispatch(CreatorsStock.stockLoading(false));
        enqueueSnackbar(` ${getErrors(error)}`, { variant: "error" });
      });
  });

  async function createInventory() {
    try {
      if (!inventory.storage_id) {
        enqueueSnackbar(`Deve-se primeiro selecionar um Armazém`, {
          variant: "warning",
        });
        return;
      }

      if (!inventory.title) {
        enqueueSnackbar(
          `Deve-se primeiro digitar um nome para este inventário`,
          {
            variant: "warning",
          }
        );
        return;
      }

      if (variations_id.length === 0) {
        enqueueSnackbar(`Não existe nenhuma variação selecionada.`, {
          variant: "warning",
        });
        return;
      }
      dispatch(CreatorsInventory.inventoryLoading(true));
      const { codigo } = await CreatorsInventory.postInventorySave({
        stock_id: inventory.storage_id,
        name: inventory.title,
        type_id: inventory.type_id,
      });
      await CreatorsInventory.postInventoryProductsVariations({
        inventory_id: codigo,
        ids: variations_id,
        stock_id: inventory.storage_id,
      });

      setInventory({
        title: "",
        type_id: 3,
        storage_id: "",
      });

      close();

      enqueueSnackbar(`Inventário criado com sucesso.`, {
        variant: "success",
      });

      dispatch(CreatorsInventory.inventoryLoading(false));
    } catch (error) {
      Sentry.captureException(error);
      dispatch(CreatorsInventory.inventoryLoading(false));
      enqueueSnackbar(error, {
        variant: "error",
      });
    }
  }

  return (
    <>
      <Loading
        loading={loadingStock || loadingInventory}
        sx={{ zIndex: 5000 }}
      />
      <Dialog
        fullWidth
        visible={open}
        title="Criar inventário"
        buttons={[
          {
            icon: <XSquare />,
            title: "Cancelar",
            disabled: false,
            color: "#ffffff",
            style: {},
            otherParams: {
              color: "neutral",
              variant: "contained",
            },
            onClick: () => close(),
          },
          {
            icon: <CheckSquare />,
            title: "Criar inventário",
            disabled: false,
            style: {},
            otherParams: {
              color: "success",
              variant: "contained",
            },
            onClick: () => createInventory(),
          },
        ]}
      >
        <Grid container spacing={1}>
          <Grid item sm={12} sx={{ fontSize: 12, mb: 2 }}>
            {title}
          </Grid>
          <Grid item sm={4}>
            <InputSelect
              label="Armazém"
              value={inventory.storage_id}
              onChange={(e) =>
                setInventory((state) => ({
                  ...state,
                  storage_id: e.target.value,
                }))
              }
              values={[
                { label: " - ", value: null },
                ...storages.map((o) => ({ label: o.nome, value: o.codigo })),
              ]}
            />
          </Grid>
          <Grid item sm={8}>
            <InputText
              label="Descrição do inventário"
              value={inventory.title}
              onChange={(e) =>
                setInventory((state) => ({ ...state, title: e.target.value }))
              }
            />
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
}

export default CreateInventoryVariations;
