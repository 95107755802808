/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";

import {
  NavLink as RouterLink,
  matchPath,
  useLocation,
} from "react-router-dom";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Collapse,
  List,
  ListItem
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import {
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
} from "@mui/icons-material";
import {
  CornerDownRight as SubItemIcon,
} from "react-feather";

import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  item: {
    display: "flex",
    flexDirection: "column",
    padding: "0px !important",
  },
  link: {
    justifyContent: "flex-start",
    letterSpacing: 0,
    backgroundColor: "blue",
    textTransform: "none !important",
    color: `${theme.palette.weesu.dark_blue} !important`,
    width: "100%",
    height: 40,
    "& svg": {
      marginRight: "8px",
    },
  },
  subLink: {
    color: theme.palette.text.primary,
    marginLeft: "4px",
    position: "relative",

    "&:hover": {
      color: `${theme.palette.weesu.light_blue} !important`,
    },

    "&::before": {
      content: '""',

      position: "absolute",
      top: "14px",
      left: "0px",
      width: "10px",
      height: "1px",
      background: theme.palette.primary.main,
    },
  },
  linkActive: {
    marginLeft: "1px !important",
    color: `${theme.palette.weesu.light_blue} !important`,
    borderLeft: `4px solid ${theme.palette.weesu.dark_blue} !important`,
  },
  subLinkActive: {
    color: theme.palette.weesu.light_blue,
    "&::before": {
      content: '""',

      position: "absolute",
      top: "15px",
      left: "-1px",
      width: "10px",
      height: "1px",
      background: theme.palette.weesu.light_blue,
    },
  },
  dropdownButton: {
    position: "relative",
    "&::before": {
      content: '""',

      position: "absolute",
      top: "0",
      left: "2px",
      width: "2px",
      height: "calc(100% - 20px)",
      background: theme.palette.primary.main,
    },
  },
}));

const NavItem = ({
  disabled,
  href,
  icon: Icon,
  title,
  subitems = [],
  subitemsVisible = "",
  setSubitemsVisible = () => {},
  selectedSubItem = "",
  setSelectedSubItem = () => {},
  ...rest
}) => {
  const location = useLocation();

  const active = href
    ? !!matchPath(
        {
          path: href,
          end: false,
        },
        location.pathname
      )
    : false;

  const handleToggleChildren = (item) => {
    if (item !== subitemsVisible) {
      setSubitemsVisible(item);
    } else {
      setSubitemsVisible(null);
    }
  };

  const handleSelectSubItem = (item, position) => setSelectedSubItem(item);

  const classes = useStyles();

  return (
    <ListItem
      className={clsx(
        classes.item,
        subitemsVisible === title && classes.dropdownButton
      )}
      disabled={disabled}
      disablegutters="true"
      {...rest}
    >
      {subitems.length ? (
        <>
          <Button
            className={clsx(classes.link, subitemsVisible === title && classes.linkActive)}
            onClick={() => handleToggleChildren(title)}
          >
            <Icon />
            <span>{title}</span>
            <Box marginLeft="auto">
              {subitemsVisible === title ? (
                <ExpandLessIcon />
              ) : (
                <ExpandMoreIcon />
              )}
            </Box>
          </Button>
          {subitems
            .filter((i) => i.disabled == false)
            .map((item) => (
              <Collapse
                in={subitemsVisible === title ? true : false}
                key={item.title}
                timeout="auto"
                unmountOnExit
                sx={{ width: "100%" }}
              >
                <List
                  component="div"
                  disablegutters="true"
                  sx={{ paddingBottom: "10px" }}
                >
                  <ListItem
                    sx={{ height: 25, width: "98%" }}
                    className={clsx(
                      !item.disabled && classes.subLink,
                      selectedSubItem === item.title &&
                        !item.disabled &&
                        classes.subLinkActive
                    )}
                    component={RouterLink}
                    to={item.href}
                    onClick={() => handleSelectSubItem(item.title)}
                    disabled={item.disabled}
                  >
                    <SubItemIcon size="20" />
                    <span style={{ marginLeft: 5, fontSize: 14 }}>
                      {item.title}
                    </span>
                  </ListItem>
                </List>
              </Collapse>
            ))}
        </>
      ) : (
        <Button
          component={RouterLink}
          className={clsx(classes.link, active && classes.linkActive)}
          to={href}
        >
          {Icon && <Icon />}
          <span style={{ marginRight: "auto" }}>{title}</span>
        </Button>
      )}
    </ListItem>
  );
};

NavItem.propTypes = {
  href: PropTypes.string,
  icon: PropTypes.elementType,
  title: PropTypes.string,
};

export default NavItem;
