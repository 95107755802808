import { Box, Card, CardContent, CardHeader, Divider } from "@mui/material";
import React from "react";
import { Bar } from "react-chartjs-2";
import { getMobileOperatingSystem } from "src/utils/getUtils";

function BarVerticalChart({
  labels = [],
  values = [],
  title,
  height = 120,
  divider,
  display = false,
}) {
  const data = {
    labels: labels,
    datasets: [
      ...values.map((item) => ({
        ...item,
      })),
    ],
  };

  const options = {
    indexAxis: "x",
    elements: {
      bar: {
        borderWidth: 0.5,
      },
    },
    plugins: {
      legend: {
        display: display,
      },
      datalabels: {
        backgroundColor: function (context) {
          return context.dataset.backgroundColor;
        },
        formatter: Math.round,
        font: {
          size: 10,
          weight: "bold",
        },
        borderRadius: 4,
        color: "white",
      },
    },
    scales: {
      y: {
        ticks: {
          font: {
            size: 10,
            beginAtZero: true,
          },
        },
      },
      x: {
        ticks: {
          font: {
            size: 10,
            beginAtZero: true,
          },
        },
      },
    },
    responsive: true,
  };

  return (
    <Card
      style={{
        display: "flex",
        flexDirection: "column",
        margin: getMobileOperatingSystem() !== "unknown" ? 2.5 : 0,
      }}
    >
      <CardHeader
        title={title}
        style={{ padding: 0 }}
        titleTypographyProps={{
          variant: "h6",
          style: { fontSize: 16, marginTop: 10, marginLeft: 10 },
        }}
      />
      {divider && <Divider />}
      <CardContent>
        <Box
          style={{
            height:
              getMobileOperatingSystem() !== "unknown"
                ? height * 1.1
                : height * 1.6,
          }}
        >
          <Bar options={options} data={data} height={height} />
        </Box>
      </CardContent>
    </Card>
  );
}

export default BarVerticalChart;
