import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  cardStyle: {
      display: 'flex',
      flexDirection: 'row',
      padding: 10,
      [theme.breakpoints.down("sm")]: {
          flexDirection: 'column',
          paddingLeft: 15,
          paddingRight: 15,
      },
  },
  cardRowStyle: {
      display: 'flex',
      flexDirection: 'row',
      padding: 10,
      [theme.breakpoints.down("sm")]: {
          paddingLeft: 15,
          paddingRight: 15,
      },
  },
  cardColumnStyle: {
      display: 'flex',
      flexDirection: 'column',
      padding: 10,
      [theme.breakpoints.down("sm")]: {
          paddingLeft: 15,
          paddingRight: 15,
      },
  },
  imgStyle: {
      width: 50,
      height: 50,
      borderRadius: 10,
      objectFit: "cover",
      [theme.breakpoints.down("sm")]: {
          width: 30,
          height: 30,
      }
  },
  itemLista: {
    marginTop: 'auto', 
    marginBottom: "auto"
  },
  separator: {
    width: 1, 
    height: 40, 
    marginLeft: 10, 
    marginRight: 10,
    marginTop: 'auto',
    marginBottom: 'auto',
    [theme.breakpoints.down("sm")]: {
        height: 30, 
        marginLeft: 5, 
        marginRight: 5,
    }
  }
}))

export default useStyles;