import { httpfb } from "src/services";
import queryString from "query-string";

// Action Types
export const Types = {
    PICKING_BOX_LOADING:'picking/box/loading',
    PICKING_BOX_SET_DATA:'picking/box/set/data',
    PICKING_BOX_SET_PAGING:'picking/box/set/paging',
}

// Reducer
const initialState = {
    loading: false,
    picking_box: [],
    paging: { limit: 25, offset: 0, total: 0},
};

// Action Creators
export default function reducer(state = initialState, action) {
    switch (action.type) {
        case Types.PICKING_BOX_LOADING:
            return { ...state, loading: action.payload };
        case Types.PICKING_BOX_SET_DATA:
            return { ...state, picking_box: action.payload };
        case Types.PICKING_BOX_SET_PAGING:
            return { ...state, paging: action.payload };
      default:
        return state;
    }
}

export const Creators = {
    pickingBoxLoading: value => ({ type: Types.PICKING_BOX_LOADING, payload: value }),
    pickingBoxSetData: value => ({ type: Types.PICKING_BOX_SET_DATA, payload: value }),
    pickingBoxSetPaging: paging => ({ type: Types.PICKING_BOX_SET_PAGING, payload: paging }),
    getPickingBoxList: (filters={}) => {
        const qry = queryString.stringify(filters, { arrayFormat: "index"})
        return new Promise( (resolve, reject) => {
            httpfb.get(`/logistics/picking-box?${qry}`, {timeout: 1000 * 15})
                .then(({ data: { data, paging } }) => {
                    resolve({ data, paging })
                })
                .catch( error => reject(error))
        })
    },
    setPickingBox: () => {
        return new Promise( (resolve, reject) => {
            httpfb.post(`/logistics/picking-box`, {timeout: 1000 * 15})
                .then(({ data }) => resolve(data))
                .catch( error => reject(error))
        })
    },
    delPickingBox: id => {
        return new Promise( (resolve, reject) => {
            httpfb.delete(`/logistics/picking-box/${id}`, {timeout: 1000 * 15})
                .then(({ data }) => resolve(data))
                .catch( error => reject(error))
        })
    },
    getPickingBox: key => {
        return new Promise( (resolve, reject) => {
            httpfb.get(`/logistics/picking-box/key/${key}`, {timeout: 1000 * 15})
                .then(({ data }) => resolve(data))
                .catch( error => reject(error))
        })
    },
    getPickingBoxPdf: ids => {
        return new Promise( (resolve, reject) => {
            let _ids = ids.reduce((acc, som, i) => acc += `${som}${i === ids.length-1 ? "": ","}`, "")

            if (ids.length > 1){
                httpfb.get(`/logistics/picking-box/label-pdf/ids/${_ids}`, {timeout: 1000 * 15})
                    .then(({ data }) => resolve(data))
                    .catch( error => reject(error))
            } else {
                httpfb.get(`/logistics/picking-box/label-pdf/${ids[0]}`, {timeout: 1000 * 15})
                    .then(({ data }) => resolve(data))
                    .catch( error => reject(error))
            }

        })
    },
}