/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Card, CardContent, CardHeader, Divider } from "@mui/material";
import React from "react";
import { Line } from "react-chartjs-2";
import { getMobileOperatingSystem } from "src/utils/getUtils";

function LineChartTwoAxis({
  labels = [],
  valuesAxis1 = [],
  valuesAxis2 = [],
  title,
  height = 120,
  divider,
}) {
  const data = {
    labels: labels,
    datasets: [
      ...valuesAxis1.map((item) => ({
        ...item,
        fill: false,
        yAxisID: "y-axis-1",
        datalabels: {
          align: "center",
          anchor: "center",
        },
      })),
      ...valuesAxis2.map((item) => ({
        ...item,
        fill: false,
        yAxisID: "y-axis-2",
        datalabels: {
          align: "center",
          anchor: "center",
        },
      })),
    ],
  };

  const options = {
    plugins: {
      datalabels: {
        backgroundColor: function (context) {
          return context.dataset.backgroundColor;
        },
        padding: 6,
        formatter: Math.round,
        font: {
          weight: "bold",
        },
        borderRadius: 4,
        color: "white",
      },
    },
    layout: {
      padding: {
        top: 2,
        right: 10,
        bottom: 3,
        left: 5,
      },
    },
    elements: {
      line: {
        fill: false,
        tension: 0.4,
      },
    },
    scales: {
      yAxes: [
        { type: "linear", display: true, position: "left", id: "y-axis-1" },
        {
          type: "linear",
          display: valuesAxis2.length == 1 ? false : true,
          position: "right",
          id: "y-axis-2",
          gridLines: { drawOnArea: false },
        },
      ],
    },
  };

  return (
    <Card
      style={{
        display: "flex",
        flexDirection: "column",
        margin: getMobileOperatingSystem() !== "unknown" ? 2.5 : 0,
      }}
    >
      <CardHeader
        title={title}
        style={{ padding: 0 }}
        titleTypographyProps={{
          variant: "h6",
          style: { fontSize: 16, marginTop: 10, marginLeft: 10 },
        }}
      />
      {divider && <Divider />}
      <CardContent>
        <Box
          style={{
            height:
              getMobileOperatingSystem() !== "unknown"
                ? height * 9.5
                : height * 3.5,
          }}
        >
          <Line
            data={data}
            options={options}
            height={
              getMobileOperatingSystem() !== "unknown" ? height * 9.5 : height
            }
          />
        </Box>
      </CardContent>
    </Card>
  );
}

export default LineChartTwoAxis;
