import { TextField } from '@mui/material'
import { makeStyles } from "@mui/styles";
const useStyles = makeStyles({
    textField: {
        width: '100%',
        maxWidth: '100%',
    },
})

function InputText(props) {
    const classes = useStyles()
    return (
      <TextField
        {...props}
        disabled={props.disabled}
        autoFocus
        inputRef={props.inputRef}
        className={classes.textField}
        label={props.label}
        required={props.required}
        value={props.value || ""}
        onChange={props.onChange}
        onBlur={props.onBlur}
        inputProps={{
          ...props.InputProps,
          maxLength: props.maxLength,
          inputMode: props.inputMode || "text",
        }}
        InputLabelProps={{ shrink: true }}
        margin="dense"
        variant="outlined"
        size="small"
      />
    );
}

export default InputText
