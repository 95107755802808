import InputIcon from "@mui/icons-material/Input";
import MenuIcon from "@mui/icons-material/Menu";
import { AppBar, Box, IconButton, Toolbar, useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "src/assets/images/logo-weesu.svg";
import { useAuth } from "src/hooks";
import { Creators } from "src/store/ducks/user";

const useStyles = makeStyles((theme) => ({
  togglerSidebar: {
    display: "none",
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
  },
  headerIcons: {
    display: "none",
    [theme.breakpoints.up("lg")]: {
      display: "block",
    },
  },
}));

const DashboardNavbar = ({ onMobileNavOpen, ...rest }) => {
  const theme = useTheme()
  // const [notifications] = useState([]);
  const dispatch = useDispatch();
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const { signOut } = useAuth();
  // useEffect(() => dispatch(Creators.getUser(navigate)), [location.pathname]);

  // function signOut() {
    // localStorage.removeItem("access_token");
    // navigate("/", { replace: true });
  // }

  return (
    <AppBar elevation={0} {...rest} sx={{ backgroundColor: theme.palette.weesu.dark_blue }}>
      <Toolbar>
        {!window.location.href.includes("separacao_") ? (
          <RouterLink to="/app/home">
            <img src={logo} style={{ width: 150, height: 55 }} alt="" />
          </RouterLink>
        ): (
          <img src={logo} style={{ width: 150, height: 55 }} alt="" />  
        )}
        <Box sx={{ flexGrow: 1 }} />
        <Box className={classes.headerIcons}>
          {/* <IconButton color="inherit">
            <Badge
              badgeContent={notifications.length}
              color="primary"
              variant="dot"
            >
              <NotificationsIcon />
            </Badge>
          </IconButton> */}
          <IconButton onClick={signOut} color="inherit">
            <InputIcon />
          </IconButton>
        </Box>
        {!window.location.href.includes("separacao_") && (
          <Box className={classes.togglerSidebar}>
            <IconButton color="inherit" onClick={onMobileNavOpen}>
              <MenuIcon />
            </IconButton>
          </Box>
        )}
      </Toolbar>
      <div
        style={{
          height: "3px",
          position: "absolute",
          width: "100%",
          bottom: "0",
          left: "0",
          backgroundImage: `linear-gradient(to right, #F4F6F8, #42B7BC)`,
        }}
      ></div>
    </AppBar>
  );
};

DashboardNavbar.propTypes = {
  onMobileNavOpen: PropTypes.func,
};

export default DashboardNavbar;
