import { http } from "src/services";

// Action Types
export const Types = {
  USER_LOADING: "user/loading",
  USER_SET_DATA: "user/set/data",
  USERS_SET_DATA: "user/list/set/data",
  USERS_SET_PAGING: "user/list/set/paging",
};

// Reducer
const initialState = {
  loading: false,
  users: [],
  user: {},
  paginate: {
    page: 0,
    size: 15,
    count: 0,
  },
};

// Action Creators
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.USER_LOADING:
      return { ...state, loading: action.payload };
    case Types.USERS_SET_PAGING:
      return { ...state, paginate: { ...action.payload } };
    case Types.USERS_SET_DATA:
      return { ...state, users: action.payload };
    case Types.USER_SET_DATA:
      return { ...state, user: action.payload };
    default:
      return state;
  }
}

export const Creators = {
  userLoading: (value) => ({ type: Types.USER_LOADING, payload: value }),
  usersSetData: (users) => ({ type: Types.USERS_SET_DATA, payload: users }),
  usersSetPaginate: (paging) => ({
    type: Types.USERS_SET_PAGING,
    payload: paging,
  }),
  userSetData: (user) => ({ type: Types.USER_SET_DATA, payload: user }),
  findAllUsers: (params) => {
    return (dispatch) => {
      http
        .get(`/core/users?${new URLSearchParams({ ...params })}`)
        .then(({ data: { rows, page, size, count } }) => {
          dispatch([
            Creators.usersSetData(rows),
            Creators.usersSetPaginate({ page, size, count }),
            Creators.userLoading(false),
          ]);
        })
        .catch((e) => {
          dispatch([Creators.userLoading(false)]);
        });
    };
  },
  findUser: (id, callback) => {
    return (dispatch) => {
      http
        .get(`/core/users/${parseInt(id)}`)
        .then(({ data }) => {
          dispatch([Creators.userSetData(data), Creators.userLoading(false)]);
          callback(data);
        })
        .catch((e) => {
          dispatch([Creators.userLoading(false)]);
        });
    };
  },
  saveNewUser: (user, callback) => {
    return (dispatch) => {
      http
        .post(`/core/users`, user)
        .then(() => {
          dispatch([Creators.userLoading(false)]);
          callback({ success: true });
        })
        .catch((e) => {
          dispatch([Creators.userLoading(false)]);
          callback({ success: false, error: e });
        });
    };
  },
  updateUser: (user, id, callback) => {
    return (dispatch) => {
      http
        .put(`/core/users/${id}`, user)
        .then(() => {
          dispatch([Creators.userLoading(false)]);
          callback({ success: true });
        })
        .catch((e) => {
          dispatch([Creators.userLoading(false)]);
          callback({ success: false, error: e });
        });
    };
  },
  removeUser: (id, callback) => {
    return (dispatch) => {
      http
        .delete(`/core/users/${id}`)
        .then(() => {
          dispatch([Creators.userLoading(false)]);
          callback({ success: true });
        })
        .catch((e) => {
          dispatch([Creators.userLoading(false)]);
          callback({ success: false, error: e });
        });
    };
  },
};
