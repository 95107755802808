/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { NavigateNext } from "@mui/icons-material";
import { Breadcrumbs, Typography } from "@mui/material";
import React from "react";
import { getMobileOperatingSystem } from "src/utils/getUtils";

function BreadCrumbs({ values = [] }) {
  return (
    <Breadcrumbs
      aria-label="breadcrumb"
      sx={{ mt: 1, mb: 1, ml: -1 }}
      separator={<NavigateNext fontSize="small" />}
    >
      {getMobileOperatingSystem() == "unknown" ? (
        values.map((item, index) => (
          <Typography color="textPrimary" key={index}>
            {item}
          </Typography>
        ))
      ) : (
        <Typography color="textPrimary">{values[values.length - 1]}</Typography>
      )}
    </Breadcrumbs>
  );
}

export default BreadCrumbs;
