import { createTheme as createMuiTheme } from "@mui/material";
import { ptBR as corePtBR } from "@mui/material/locale";
import { ptBR as dpPtBR } from "@mui/x-date-pickers/locales";

import shadows from "./shadows";
import typography from "./typography";
import { createPalette } from "./palette";

export function createTheme() {
  const palette = createPalette();
  return createMuiTheme(
    {
      palette,
      shadows,
      typography,
      components: {
        MuiCheckbox: {
          styleOverrides: {
            root: {
              color: palette.weesu.dark_blue,
              "& .Mui-checked": {
                color: palette.weesu.light_blue,
              },
            },
            checked: {
              color: palette.weesu.light_blue,
              backgroundColor: palette.weesu.light_blue,
            },
          },
        },
        MuiLinearProgress: {
          styleOverrides: {
            root: {
              borderRadius: "5px",
            },
            barColorPrimary: {
              backgroundColor: palette.weesu.light_blue,
            },
          },
        },
        MuiOutlinedInput: {
          styleOverrides: {
            root: {
              ":hover:not($disabled)": {
                backgroundColor: palette.background.default,
              },
            },
            input: {
              padding: "11.5px 10px",
            },
          },
        },
      },
      zIndex: {
        appBar: 2200,
        drawer: 2100,
      },
    },
    corePtBR,
    dpPtBR
  );
};
