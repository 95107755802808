import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  cardStyle: {
    display: "flex",
    flexDirection: "row",
    padding: 10,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      paddingLeft: 15,
      paddingRight: 15,
    },
  },
  cardRowStyle: {
    display: "flex",
    flexDirection: "row",
    padding: 10,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 15,
      paddingRight: 15,
    },
  },
  cardColumnStyle: {
    display: "flex",
    flexDirection: "column",
    padding: 10,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 15,
      paddingRight: 15,
    },
  },
  imgStyle: {
    width: 130,
    height: 130,
    borderRadius: 10,
    objectFit: "cover",
    [theme.breakpoints.down("sm")]: {
      width: 80,
      height: 80,
    },
  },
  divArmazen: {
    display: "flex",
    flexDirection: "row",
    //backgroundColor: 'rgba(3, 36, 47, 0.1)',
    backgroundColor: "#D9EFEF",
    borderRadius: 10,
    paddingLeft: 20,
    paddingTop: 5,
    paddingBottom: 5,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 10,
    },
  },
  iconArmazen: {
    width: 50,
    height: 50,
    [theme.breakpoints.down("sm")]: {
      width: 35,
      height: 35,
    },
  },
}));

export default useStyles;
