import { Box } from "@mui/material";

export const SplashScreen = () => (
  <Box
    sx={{
      alignItems: "center",
      backgroundColor: "#03242F",
      display: "flex",
      flexDirection: "column",
      height: "100vh",
      justifyContent: "center",
      left: 0,
      p: 3,
      position: "fixed",
      top: 0,
      width: "100vw",
      zIndex: 1400,
    }}
  >
    <Box
      sx={{
        display: "inline-flex",
        width: "392px",
        height: "140px",
      }}
    >
      <img
        src={require("../../../assets/images/logo-weesu.png")}
        alt="logo"
        style={{
          width: "392px",
          height: "140px",
        }}
      />
    </Box>
  </Box>
);
