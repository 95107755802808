/* eslint-disable eqeqeq */
import React from "react";
import styled from "@emotion/styled";
import {
  LinearProgress,
  linearProgressClasses,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

const WessuLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 15,
  borderRadius: 7.5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "transparent",
    border: `2px solid ${theme.palette.weesu.light_blue}`,
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.weesu.light_blue,
  },
}));

const useStyles = makeStyles((theme) => ({
  itemLista: {
    marginTop: "auto",
    marginBottom: "auto",
  },
}));

function BorderLinearProgress({ atual = 0, total = 0, style = {} }) {
  const classes = useStyles();
  function normalise(value, MIN, MAX) {
    return ((value - MIN) * 100) / (MAX - MIN);
  }

  return (
    <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
      <Typography
        className={classes.itemLista}
        sx={{ width: "100%", marginTop: "auto", fontWeight: "bold" }}
        align="center"
      >
        {atual} - {total}
      </Typography>
      <WessuLinearProgress
        variant="determinate"
        value={normalise(atual, 0, total)}
        sx={{
          width: "50%",
          marginBottom: "auto",
          marginLeft: "auto",
          marginRight: "auto",
          height: 20,
          opacity: atual == total ? 0.2 : 1,
          ...style,
        }}
      />
    </div>
  );
}

export default BorderLinearProgress;
