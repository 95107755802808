export const permissionLevels = {
  noPermission: 0,
  viewOnly: 1,
  viewAndEdit: 2,
  viewAndCreate: 3,
  fullPermission: 4,
};

export const modules = {
  start: {
    path: "/",
    title: "",
  },
  login: {
    path: "/login",
    title: "Login",
  },
  home: {
    id: 1,
    path: "/app/home",
    title: "Dashboard",
    level: [permissionLevels.noPermission, permissionLevels.fullPermission],
    params: [
      "card-waiting-picking",
      "card-waiting-packing",
      "card-waiting-shipment",
      "card-waiting-markeplaces",
      "chart-expedition-hour",
      "chart-picking-user",
      "chart-packing-user",
    ],
  },
  picking: {
    id: 2,
    path: "/app/expedicao/separacao",
    title: "Separação",
    level: [permissionLevels.noPermission, permissionLevels.fullPermission],
    params: [
      "profile-picking",
      "profile-allow-adding-separation-box",
      "profile-allow-remove-separation-box",
      "profie-picking-box-confirmation",
    ],
  },
  picking_box: {
    id: 2,
    path: /^\/app\/expedicao\/separacao_caixa\/\d+$/gm,
    regex: true,
    title: "Separação Caixa",
    level: [permissionLevels.noPermission, permissionLevels.fullPermission],
    params: [
      "profile-picking",
      "profile-allow-adding-separation-box",
      "profile-allow-remove-separation-box",
      "profie-picking-box-confirmation",
    ],
  },
  picking_proccess: {
    id: 2,
    path: /^\/app\/expedicao\/separacao_operacao\/\d+$/gm,
    regex: true,
    title: "Separação Caixa",
    level: [permissionLevels.noPermission, permissionLevels.fullPermission],
    params: [
      "profile-picking",
      "profile-allow-adding-separation-box",
      "profile-allow-remove-separation-box",
      "profie-picking-box-confirmation",
    ],
  },
  packingBox: {
    id: 3,
    path: "/app/expedicao/conferencia",
    title: "Conferência para expedição",
    level: [permissionLevels.viewOnly, permissionLevels.fullPermission],
    params: [],
  },
  pickingBox: {
    id: 4,
    path: "/app/cadastro/caixa_separacao",
    title: "Caixa de separação",
    level: [
      permissionLevels.noPermission,
      permissionLevels.viewOnly,
      permissionLevels.viewAndCreate,
      permissionLevels.fullPermission,
    ],
    params: ["print-separation-box"],
  },
  packaging: {
    id: 5,
    path: "/app/cadastro/embalagens",
    title: "Embalagens",
    level: [],
    params: [],
  },
  stockAddress: {
    id: 6,
    path: "/app/cadastro/enderecos",
    title: "Endereços de estoque",
    level: [],
    params: [],
  },
  productsListing: {
    id: 7,
    path: "/app/estoque/listagem/produtos",
    title: "Produtos",
    level: [permissionLevels.noPermission, permissionLevels.fullPermission],
    params: [
      "button-inventory-products",
      "button-inventory-variation",
      "button-remove-address-mapped",
    ],
  },
  quickSearch: {
    id: 8,
    path: "/app/estoque/pesquisa/rapida",
    title: "Pesquisa rápida",
    level: [permissionLevels.noPermission, permissionLevels.fullPermission],
    params: ["button-shortcut-allocation"],
  },
  stockMapping: {
    id: 9,
    path: "/app/estoque/mapeamento/produtos",
    title: "Mapeamento",
    level: [],
    params: [],
  },
  stockAllocation: {
    id: 10,
    path: "/app/estoque/alocacao/produtos",
    title: "Alocação",
    level: [],
    params: ["button-allocate-quantity"],
  },
  inventoryMovement: {
    id: 11,
    path: "/app/estoque/movimentacao/produtos",
    title: "Movimentação",
    level: [],
    params: [],
  },
  stockTransfer: {
    id: 12,
    path: "/app/estoque/transferencia/produtos",
    title: "Transferências",
    level: [permissionLevels.noPermission, permissionLevels.fullPermission],
    params: [],
  },
  stockTransferEdit: {
    id: 12,
    path: /^\/app\/estoque\/transferencia\/produtos\/\d+$/gm,
    regex: true,
    title: "Transferências",
    level: [permissionLevels.noPermission, permissionLevels.fullPermission],
    params: [],
  },
  freeInventory: {
    id: 13,
    path: "/app/estoque/inventario/livre",
    title: "Inventário livre",
    level: [permissionLevels.noPermission, permissionLevels.fullPermission],
    params: [],
  },
  takeInventory: {
    id: 14,
    path: "/app/estoque/inventariar/lista",
    title: "Inventariar",
    level: [permissionLevels.noPermission, permissionLevels.fullPermission],
    params: [],
  },
  takeInventorying: {
    id: 14,
    path: /^\/app\/estoque\/inventariar\/\d+\/endereco\/\d+$/gm,
    regex: true,
    title: "Inventariar",
    level: [permissionLevels.noPermission, permissionLevels.fullPermission],
    params: [],
  },
  createInventory: {
    id: 15,
    path: "/app/estoque/inventario/lista",
    title: "Inventários",
    level: [
      permissionLevels.noPermission,
      permissionLevels.viewOnly,
      permissionLevels.viewAndCreate,
      permissionLevels.viewAndEdit,
    ],
    params: ["button-approve-item", "button-reject-item", "button-delete-item"],
  },
  editedInventory: {
    id: 15,
    path: /^\/app\/estoque\/inventario\/editar\/\d+$/gm,
    regex: true,
    title: "Inventários - editar",
    level: [
      permissionLevels.noPermission,
      permissionLevels.viewOnly,
      permissionLevels.viewAndCreate,
      permissionLevels.viewAndEdit,
    ],
    params: ["button-approve-item", "button-reject-item", "button-delete-item"],
  },
  deliveryVolume: {
    id: 16,
    path: "/app/expedicao/relacao_de_volumes",
    title: "Relação de volumes",
    level: [],
    params: [],
  },
  reportStockDivergence: {
    id: 17,
    path: "/app/relatorios/divergencia_estoque",
    title: "Divergências de estoque",
    level: [permissionLevels.noPermission, permissionLevels.viewOnly],
    params: [
      "button-inventory-variation",
      "button-remove-address-mapped",
      "button-adjust-stock-divergence",
    ],
  },
  reportEmptyAddress: {
    id: 18,
    path: "/app/relatorios/endereco_zerado",
    title: "Endereços zerados",
    level: [],
    params: [],
  },
  reportCurveA: {
    id: 19,
    path: "/app/relatorios/curva_a",
    title: "Produtos p/ Curva ~ A",
    level: [],
    params: [],
  },
  userControl: {
    id: 20,
    path: "/app/sistema/usuarios",
    title: "Usuários",
    level: [
      permissionLevels.noPermission,
      permissionLevels.viewOnly,
      permissionLevels.viewAndCreate,
      permissionLevels.viewAndEdit,
      permissionLevels.fullPermission,
    ],
    params: [],
  },
  editUserControl: {
    id: 20,
    path: /^\/app\/sistema\/usuarios\/cadastro\/\d+$/gm,
    regex: true,
    title: "Usuários",
    level: [
      permissionLevels.noPermission,
      permissionLevels.viewOnly,
      permissionLevels.viewAndCreate,
      permissionLevels.viewAndEdit,
      permissionLevels.fullPermission,
    ],
    params: [],
  },
  logView: {
    id: 21,
    path: "/app/sistema/visualizar/logs",
    title: "Visualizar Logs",
    level: [],
    params: [],
  },
  pickingPriority: {
    id: 22,
    path: "/app/sistema/prioridade-separacao",
    title: "Prioridade de separação",
    level: [permissionLevels.noPermission, permissionLevels.fullPermission],
    params: [],
  },
  takeTransfer: {
    id: 23,
    path: "/app/estoque/transferir",
    title: "Transferir",
    level: [permissionLevels.noPermission, permissionLevels.fullPermission],
    params: [
      "button-transfer-approve-item",
      "button-transfer-remove-item",
      "button-transfer-edit-item",
    ],
  },
  takeTransferEdit: {
    id: 23,
    path: /^\/app\/estoque\/transferir\/\d+$/gm,
    regex: true,
    title: "Transferir",
    level: [permissionLevels.noPermission, permissionLevels.fullPermission],
    params: [
      "button-transfer-approve-item",
      "button-transfer-remove-item",
      "button-transfer-edit-item",
    ],
  },
};
